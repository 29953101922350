import { Image } from "expo-image";
import { Platform, Pressable, Text, View } from "react-native";
import { useTailwind } from "tailwind-rn";
import React, { useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setstorestatus } from "../api";
import { useSelector, useDispatch } from "react-redux";
import { setIsStoreActive } from "../utils/redux/slice/StoreSlice";
import { Modal, Portal } from "react-native-paper";
import StoreClosePopup from "./StoreClosePopup";
import Toast from "react-native-toast-message";


const StoreBtn = ({}: any) => {
  const tailwind = useTailwind();
  const dispatch = useDispatch();
  const selectedStore = useSelector((state) => state?.store?.selectedStore);
  const isStoreActive = useSelector((state) => state?.store?.isStoreActive);
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const showToast = ({ type, text }) => {
    Toast.show({
      type: type,
      text1: text,
    });
  };

  const dispatchIsStoreActive = (message, success) => {

    showToast({ type: "success", text: message });
    // showToast({ type: "error", text: message });

    dispatch(setIsStoreActive(!isStoreActive));
  };

  const changeStoreStatus = () => {
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        const today = new Date();
        setstorestatus(
          isStoreActive ? "0" : "1",
          today.toISOString(),
          isStoreActive ? checked : "",
          token,
          selectedStore?.storeId
        ).then((res) => {
          dispatchIsStoreActive(res.data.message, true);
        });
      }
    });

    hideModal();
  };

  const [modalChild, setModalChild] = useState(
    <StoreClosePopup
      dispatchIsStoreActive={dispatchIsStoreActive}
      hideModal={hideModal}
    />
  );

  const containerStyle = {
    backgroundColor: "white",
    zIndex: 100,
    borderRadius: 10,
  };

  const modalStyles = {
    zIndex: 100,
    width: Platform.OS === "web" ? "500px" : "10px",
    marginHorizontal: "auto",
  };

  if (Platform.OS !== "web") {
    modalStyles.marginHorizontal = 10;
  }

  return (
    <View
      style={[
        tailwind(
          `mt-4 sm:mt-0 flex relative rounded-[30px] py-2.5 px-5 border-[3px] justify-between items-center flex-row border-solid ${
            isStoreActive
              ? "border-colorLimegreen_100"
              : "border-colorTomato_100"
          } `
        ),
      ]}
    >
      <Portal>
        <Modal
          visible={visible}
          onDismiss={hideModal}
          contentContainerStyle={containerStyle}
          style={modalStyles}
        >
          {modalChild}
        </Modal>
      </Portal>
      <View style={[tailwind("justify-end h-[27px] items-center flex-row")]}>
        {/* <Image
            style={tailwind('w-[9px] h-[9px] hidden')}
            contentFit="cover"
            source={require("../assets/notification.png")}
          /> */}
        <Text
          style={[
            tailwind(
              "ml-0.5 text-[18px] text-left font-bold text-colorGray_200 font-poppins-bold"
            ),
          ]}
        >
          {selectedStore?.storeId}
        </Text>
      </View>
      <Pressable
        style={[tailwind("")]}
        onPress={() => {
          if (isStoreActive) {
            showModal();
          } else {
            changeStoreStatus();
          }
        }}
      >
        <View
          style={[
            tailwind(
              `w-[50px] ml-[20px] items-center flex-row p-1 rounded-2xl ${
                isStoreActive ? "bg-colorLimegreen_100" : "bg-colorTomato_100"
              }`
            ),
          ]}
        >
          <Image
            style={[
              tailwind(
                `w-4 h-4 mt-0.5 rounded-xl ${
                  isStoreActive ? "ml-auto" : "mr-auto"
                } `
              ),
            ]}
            contentFit="contain"
            source={require("../assets/ellipse-2.png")}
          />
        </View>
      </Pressable>
    </View>
  );
};

export default StoreBtn;
