import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { Dimensions, Text, View } from "react-native";
import { LineChart } from "react-native-chart-kit";
import { lightBlue100 } from "react-native-paper/lib/typescript/styles/themes/v2/colors";
import { useTailwind } from "tailwind-rn";

const screenWidth = Dimensions.get("window").width;
const TotalOrdersChart = (props) => {

    const tailwind = useTailwind();

    const chartConfig = {
 
      backgroundColor: "#ffffff", // Solid background color
      backgroundGradientFrom: "#ffffff", // Start of gradient (same color for solid)
      backgroundGradientTo: "#ffffff", // End of gradient (same color for solid)
      decimalPlaces: 2, // number of decimal places to show
      color: (opacity = 1) => `rgba(0,0,0 , ${opacity})`,
      labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`, // X and Y axis label color
      backgroundGradientFromOpacity: 0, // Remove gradient background
      backgroundGradientToOpacity: 0,   // Remove gradient background
      fillShadowGradient: 'transparent',
      fillShadowGradientFrom: 'transparent',
      fillShadowGradientTo: 'transparent',
      fillShadowGradientOpacity: 0, // Ensure no fill color
      strokeWidth: 3, // optional, default 3
      barPercentage: 5.5,
      propsForDots: {
        r: '3',
        strokeWidth: '2',
        stroke: '#ff6384', // Points border color
        fill: '#ff6384',   // Points fill color
      },
    };
  

  const labels = props?.analyticsData?.totalOrdersVsDate?.sort((a, b) => new Date(a.date.split('-').reverse().join('-')) - new Date(b.date.split('-').reverse().join('-')))?.map((order) => order?.date)

  const data = {
    labels: labels.map((label, idx) =>
      {

        const labelRadix = Math.max(Math.floor(labels.length / 5), 1)
        return idx % labelRadix === 0 ? label : ''
      },
  ),
    datasets: [
      {
        label: "Total Orders",
        data: props?.analyticsData?.totalOrdersVsDate?.sort((a, b) => new Date(a.date.split('-').reverse().join('-')) - new Date(b.date.split('-').reverse().join('-')))?.map(
          (order) => order?.orders
        ),
        color: (opacity = 1) => `rgba(255,99,132 , ${opacity})`,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const chartWidth = useMemo(() => {
    if(Dimensions.get('window').width>=870){
      return ((Dimensions.get("window").width - 150)/3)-50;

    }
    else if(Dimensions.get('window').width<870 && Dimensions.get('window').width>=768){
      return Dimensions.get("window").width - 73;
    }

    return  Dimensions.get("window").width - 45;
  }, [Dimensions.get("window").width]);

  return (
    <View style={[tailwind("bg-white p-2 text-left text-base font-bold rounded-[7px] pt-4 w-full")]}>
      <Text style={tailwind("mb-2.5 font-poppins-bold text-base")}>
        {" "}
        Total Orders -{" "}
        <Text style={tailwind("text-colorSkyblue_100 font-poppins-bold")}>
        {props?.analyticsData?.totalOrders}
        </Text>
      </Text>
      <View style={tailwind("flex rounded-[7px]")}>
        <LineChart data={data} width={chartWidth} xLabelsOffset={-10} height={340} verticalLabelRotation={60}  chartConfig={chartConfig}    style={{
            marginRight: -28,
            marginLeft: -8,
          }}/>
      </View>
    </View>
  );
};


export default TotalOrdersChart;
