import { Image } from "expo-image";
import { Text, View } from "react-native";
import {useTailwind} from 'tailwind-rn'

const Title = () => {
    const tailwind = useTailwind();

    return (

        <View
          style={[
            tailwind("flex justify-center w-full sm:w-fit sm:h-full"),
          ]}
        >
          <View style={[tailwind("h-[39px]")]}>
            <Text
              style={[tailwind("text-3xl sm:text-xl w-full font-russo-regular")]}
            >
              <Text style={[tailwind("text-colorSkyblue_100"),]}>ORDERAT</Text>
              <Text style={tailwind("text-colorBlack")}>.IO</Text>
            </Text>
          </View>

          <View style={tailwind("w-full flex flex-col sm:flex-row sm:w-[170px] mt-[-5px] items-center flex-row")}>
            <Text
              style={[
                tailwind("text-left text-base sm:text-xs text-colorBlack font-poppins-regular"),

              ]}
            >
              Powered by
            </Text>

            <View style={[tailwind("w-[127px] h-[18px] sm:w-[88px] sm:h-[13px] ml-2 sm:ml-1")]}>
              <View
                style={[
                  tailwind(
                    "right-0 absolute w-full left-0 bottom-0 top-0 h-full"
                  ),
                ]}
              >
                <Image
                  style={[
                    tailwind(
                      "max-h-full max-w-full absolute overflow-hidden h-[47.97%] w-[67.27%] top-[30.44%] right-[8.01%] bottom-[22.59%] left-[24.72%]"
                    ),
                  ]}
                  contentFit="contain"
                  source={require("../assets/group-6.png")}
                />
                <Image
                  style={[
                    tailwind(
                      "max-h-full max-w-full absolute overflow-hidden w-[20.45%] h-full top-0 bottom-0 left-0 right-[79.55%]"
                    ),
                  ]}
                  contentFit="contain"
                  source={require("../assets/group-7.png")}
                />

                <Text
                  style={[
                    tailwind(
                      "text-left h-[22.73%] w-[7.95%] top-[15.15%] left-[92.05%] text-[4px] absolute text-colorBlack font-russo-regular"
                    ),
                  ]}
                >
                  TM
                </Text>
              </View>
            </View>
          </View>
        </View>
    )
}

export default Title;