import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Dimensions,
  Platform,
  Pressable,
  ScrollView,
  Text,
  View,
} from "react-native";
import Layout from "./Layout";
import TotalSalesChart from "./charts/TotalSalesChart";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { getstorelist } from "../api";
import axios from "axios";
import { useLinkTo } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setStoreListData } from "../utils/redux/slice/StoreSlice";
import { useTailwind } from "tailwind-rn";
import { StyleSheet } from "react-native";
import AverageCartChart from "./charts/AverageCartChart";
import TotalOrdersChart from "./charts/TotalOrdersChart";
import ReportsHeader from "./ReportsHeader";
import { Image } from "expo-image";
import Table from "./Table";
import { toZonedTime } from "date-fns-tz";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";

function parseLocaleDateString(localeDateString) {
  if (!localeDateString) return null;
  const [month, day, year] = localeDateString.split("/").map(Number);
  return new Date(year, month - 1, day); // month is 0-based in JavaScript Date
}
const REACT_APP_ORDERAT = "https://testing.orderat.io";
const Reports = () => {
  const linkTo = useLinkTo();
  const dispatch = useDispatch();
  const tailwind = useTailwind();
  const [, setorderaccepted] = useState(false);
  // const[cancelorder,setcancelorder] =useState(false)
  const [orderlist, setorderlist] = useState();
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [reportOrderList, setReportOrderList] = useState();
  const [refresh, setrefresh] = useState(false);
  const size = 10;
  const [page, setpage] = useState(1);
  const [showCount, setShowCount] = useState();
  const [currentCurrency, setCurrentCurrency] = useState();

  const [paginationLength, setPaginationLength] = useState(false);
  const [currentpage, setcurrentpage] = useState(1);
  const [storelistData, setStorelistData] = useState();
  const [reportsorderlist, setreportsorderlist] = useState();
  const [mostFrequentDropId, setmostFrequentDropId] = useState(null);
  const [bestseller, setbestseller] = useState();
  const [bestsellerno, setbestsellerno] = useState();
  const [loaderState, setloaderstate] = useState(false);
  //  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [, setOpen] = useState(false);
  const [currentStore, setcurrentStore] = useState();
  const [currentStoreType, setCurrentStoreType] = useState();
  const [currentStoreTimezone, setCurrentStoreTimezone] =
    useState("America/New_York");
  // const startDate = useSelector((state) => parseLocaleDateString(state.dateFilter.startDate));
  // const endDate = useSelector((state) => parseLocaleDateString(state.dateFilter.endDate));
  // const startDate = useSelector((state) => state.dateFilter.startDate.toLocaleDateString('en-US'));
  // const endDate = useSelector((state) => state.dateFilter.endDate.toLocaleDateString('en-US'));

  const localeStartDate = useSelector((state) => state.dateFilter.startDate);
  const localeEndDate = useSelector((state) => state.dateFilter.endDate);

  const startDate = useMemo(
    () => parseLocaleDateString(localeStartDate),
    [localeStartDate]
  );
  const endDate = useMemo(
    () => parseLocaleDateString(localeEndDate),
    [localeEndDate]
  );

  // const tableHeader = [
  //   {
  //     title: "OrderId",
  //     classes: "w-[100px]"
  //   },
  //   {
  //     title: "Status",
  //     classes: "w-[110px]"

  //   },
  //   {
  //     title: "Delivery Gate",
  //     classes: "w-[200px]"
  //   },
  //   {
  //     title: "Order Info",
  //     classes: "flex-1 min-w-[300px]"
  //   },
  //   {
  //     title: "Time Stamp",
  //     classes: "w-[120px]"
  //   },
  // ];

  const columnsToExclude = [
    "storeId",
    "statusCode",
    "currency",
    "ottonomyHit",
    "language",
    "pin",
    "paymentRefrence",
    "message",
    "OrderData",
  ];

  const exportToExcel = async (data, fileName, storeType, storeTimezone) => {
    const REACT_APP_STRIPE_PAYOUT_DETAILS = 0;

    // Create a new array to hold the rows for the Excel sheet
    const rows = [];
    let filteredData = [];
    if (storeType === "self") {
      data.forEach((order) => {
        // if(order.status !== "order cancelled") {
        let order_date = new Date(order.createdAt).toLocaleString("en-GB", {
          // hour: "numeric",
          // minute: "numeric",
          // second: "numeric",
          timeZoneName: "short",
          timeZone: currentStoreTimezone,
        });

        const newRow = {
          OrderID: order.orderId,
          Username: order.userName,
          Status: order.status,
          "Mobile Number": order.mobileNumber,
          OrderData: "",
          "Pre-Tax Amount": `${order.currency} ${parseFloat(
            order.cartTotal
          ).toFixed(2)}`,
          Tax: Boolean(parseFloat(order.tax))
            ? `${order.currency} ${order.tax}`
            : "-",
          "Pre-Discount Amount": `${order.currency} ${(
            parseFloat(order.cartTotal) + parseFloat(order.tax)
          ).toFixed(2)}`,
          "Payment Gateway Fixed Fees": `${order.currency} 0.3`,
          "Payment Gateway Variable Fees": `${order.currency} ${(
            (parseFloat(order.cartTotal) + parseFloat(order.tax)) *
            0.029
          ).toFixed(2)}`,
          Payout: `${order.currency} ${(
            parseFloat(order.cartTotal) -
            0.3 -
            (parseFloat(order.cartTotal) + parseFloat(order.tax)) * 0.029
          ).toFixed(2)}`,
          "Delivery Fee": order?.deliveryfee
            ? `${order.currency} ${(order?.deliveryfee).toFixed(2)}`
            : "-",
          "Discounted Amount": order.discount
            ? `${order.currency} ${parseFloat(order.discount).toFixed(2)}`
            : "-",
          "Total Amount": `${order.currency} ${parseFloat(
            order.totalAmount
          ).toFixed(2)}`,
          "Pickup Location":
            order.pickup.locationName &&
            Object.values(order.pickup.locationName)[0],
          "Delivery Location":
            order.drop.locationName &&
            Object.values(order.drop.locationName)[0],
          "Order Date": order_date.split(",")[0],
          "Order Time": order_date.split(",")[1],
        };
        if (!REACT_APP_STRIPE_PAYOUT_DETAILS) {
          delete newRow["Payment Gateway Fixed Fees"];
          delete newRow["Payment Gateway Variable Fees"];
          delete newRow["Payout"];
        }

        if (!storelistData[0]?.showDeliveryFee) {
          delete newRow["Delivery Fee"];
        }

        // Concatenate the name and quantity values from orderData array
        newRow.OrderData = order.orderData
          .map(
            (item) =>
              `${
                item.name.includes("x1") ? item.name.split("x1")[0] : item.name
              } X${item.quantity}`
          )
          .join(", ");

        rows.push(newRow);
        // }
      });
      // Calculate the sum of all totalAmount fields
      function isColumnEmpty(column) {
        return column.every(
          (value) =>
            value === 0 || value === "-" || value === undefined || value === ""
        );
      }

      function filterEmptyColumns(data) {
        const columns = Object.keys(data[0]);
        const nonEmptyColumns = columns.filter(
          (column) => !isColumnEmpty(data.map((row) => row[column]))
        );
        if (!nonEmptyColumns.includes("Discounted Amount")) {
          nonEmptyColumns.splice(
            nonEmptyColumns.indexOf("Pre-Discount Amount"),
            1
          );
        }
        if (!nonEmptyColumns.includes("Tax")) {
          nonEmptyColumns.splice(nonEmptyColumns.indexOf("Pre-Tax Amount"), 1);
        }
        return data.map((row) => {
          const filteredRow = {};
          nonEmptyColumns.forEach((column) => {
            filteredRow[column] = row[column];
          });
          return filteredRow;
        });
      }
      filteredData = filterEmptyColumns(rows);
      const totalPreTaxAmount = data.reduce(
        (sum, order) => sum + parseFloat(order.cartTotal),
        0
      );
      const totalPreDiscountAmount = data.reduce(
        (sum, order) => sum + parseFloat(order.cartTotal),
        0
      );
      const totalDiscountedAmount = data.reduce((sum, order) => {
        if (order?.discount) {
          return sum + parseFloat(order?.discount, 10);
        }
        return sum + 0;
      }, 0);
      const totaltaxsum = data.reduce(
        (sum, order) => sum + parseFloat(order.tax),
        0
      );

      const totalDeliveryFee = data.reduce(
        (sum, order) => sum + parseFloat(order?.deliveryfee ?? 0),
        0
      );

      const totalAmountSum = data.reduce(
        (sum, order) =>
          sum + (parseFloat(order.totalAmount) + parseFloat(order.tax)),
        0
      );

      const paymentGatewayFixedFees = data.reduce((sum, order) => sum + 0.3, 0);

      const paymentGatewayVariableFees = data.reduce(
        (sum, order) =>
          sum + (parseFloat(order.cartTotal) + parseFloat(order.tax)) * 0.029,
        0
      );

      const totalPayout = data.reduce(
        (sum, order) =>
          sum +
          (parseFloat(order.cartTotal) -
            0.3 -
            (parseFloat(order.cartTotal) + parseFloat(order.tax)) * 0.029),
        0
      );
      // Add the "Sub Total" row
      const subTotalRow = {
        OrderData: "Total",
        "Pre-Tax Amount": `${data[0]?.currency} ${totalPreTaxAmount.toFixed(
          2
        )}`,
        "Pre-Discount Amount": `${
          data[0]?.currency
        } ${totalPreDiscountAmount.toFixed(2)}`,
        "Discounted Amount": `${
          data[0]?.currency
        } ${totalDiscountedAmount.toFixed(2)}`,
        Tax: `${data[0]?.currency} ${totaltaxsum.toFixed(2)}`,
        "Delivery Fee": `${data[0]?.currency} ${totalDeliveryFee.toFixed(2)}`,
        "Total Amount": `${data[0]?.currency} ${totalAmountSum.toFixed(2)}`,
        "Payment Gateway Fixed Fees": `${
          data[0]?.currency
        } ${paymentGatewayFixedFees.toFixed(2)}`,
        "Payment Gateway Variable Fees": `${
          data[0]?.currency
        } ${paymentGatewayVariableFees.toFixed(2)}`,
        Payout: `${data[0]?.currency} ${totalPayout.toFixed(2)}`,
      };
      if (!REACT_APP_STRIPE_PAYOUT_DETAILS) {
        delete subTotalRow["Payment Gateway Fixed Fees"];
        delete subTotalRow["Payment Gateway Variable Fees"];
        delete subTotalRow["Payout"];
      }

      if (!totalDeliveryFee || !storelistData[0]?.showDeliveryFee) {
        delete subTotalRow["Delivery Fee"];
      }
      if (!totalDiscountedAmount) {
        delete subTotalRow["Discounted Amount"];
        delete subTotalRow["Pre-Discount Amount"];
      }
      if (!totaltaxsum) {
        delete subTotalRow["Tax"];
        delete subTotalRow["Pre-Tax Amount"];
      }

      filteredData.push({});
      filteredData.push(subTotalRow);
    } else {
      data.forEach((order) => {
        // if(order.status !== "order cancelled") {
        let order_date = new Date(order.createdAt).toLocaleString("en-GB", {
          // hour: "numeric",
          // minute: "numeric",
          // second: "numeric",
          timeZoneName: "short",
          timeZone: currentStoreTimezone,
        });

        const newRow = {
          OrderID: order.orderId,
          Username: order.userName,
          Status: order.status,
          "Mobile Number": order.mobileNumber,
          "Pickup Location":
            order.pickup.locationName &&
            Object.values(order.pickup.locationName)[0],
          "Delivery Location":
            order.drop.locationName &&
            Object.values(order.drop.locationName)[0],
          "Delivery Time": order.deliveryTime,
          "Order Date": order_date.split(",")[0],
          "Order Time": order_date.split(",")[1],
        };
        filteredData.push(newRow);
        // }
      });
    }

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const columnWidths = filteredData.reduce((acc, row) => {
      Object.keys(row).map((key, colIndex) => {
        const cellContent = row[key];
        const cellLength = cellContent ? cellContent.toString().length : 0;
        acc[colIndex] = Math.max(acc[colIndex] || 0, cellLength);

        return null;
      });
      return acc;
    }, []);

    // Set column widths in the !cols property of the worksheet
    worksheet["!cols"] = columnWidths.map((width) => ({ width: width + 2 }));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "sheet1");

    if (Platform.OS === "web") {
      // Web-specific code
      XLSX.writeFile(workbook, fileName + ".xlsx");
    } else {
      //mobile specific code

      const wbout = XLSX.write(workbook, {
        type: "base64",
        bookType: "xlsx",
      });
      let newFileName = fileName.split("/").join("-");
      const uri = FileSystem.cacheDirectory + newFileName + ".xlsx";

      await FileSystem.writeAsStringAsync(uri, wbout, {
        encoding: FileSystem.EncodingType.Base64,
      });

      await Sharing.shareAsync(uri, {
        mimeType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        dialogTitle: "MyWater data",
        UTI: "com.microsoft.excel.xlsx",
      });
    }
  };

  const handleExportClick = () => {
    AsyncStorage.getItem("token").then((token) => {
      axios
        .post(
          `${REACT_APP_ORDERAT}/order/list`,
          {
            page: 0,
            pageSize: 0,
            storeId: currentStore,
            startDate: startDate.toLocaleDateString("en-US"),
            endDate: endDate.toLocaleDateString("en-US"),
            timezone: currentStoreTimezone,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          exportToExcel(
            res.data?.order?.filter((item) => item.status !== "booked"),
            `order_list - ${
              startDate
                ? new Date(startDate)
                    .toLocaleString("en-GB", {
                      // hour: "numeric",
                      // minute: "numeric",
                      // second: "numeric",
                      // timeZoneName: "short",
                      // timeZone: currentStoreTimezone,
                    })
                    .split(",")[0]
                : new Date()
                    .toLocaleString("en-GB", {
                      // hour: "numeric",
                      // minute: "numeric",
                      // second: "numeric",
                      // timeZoneName: "short",
                      // timeZone: currentStoreTimezone,
                    })
                    .split(",")[0]
            } - ${
              endDate
                ? new Date(endDate)
                    .toLocaleString("en-GB", {
                      // hour: "numeric",
                      // minute: "numeric",
                      // second: "numeric",
                      // timeZoneName: "short",
                      // timeZone: currentStoreTimezone,
                    })
                    .split(",")[0]
                : new Date()
                    .toLocaleString("en-GB", {
                      // hour: "numeric",
                      // minute: "numeric",
                      // second: "numeric",
                      // timeZoneName: "short",
                      // timeZone: currentStoreTimezone,
                    })
                    .split(",")[0]
            }`,
            currentStoreType,
            currentStoreTimezone
          );
        })
        .catch((err) => {});
    });
  };

  //function to convert to a specific time zone (works for all devices, toLocaleTimeString was not working for ios and android)
  function convertToTimeZone(date, timeZone) {
    const zonedDate = toZonedTime(date, timeZone);
    return zonedDate;
  }

  const filteredOrdersByPage = () => {
    if (!startDate || !endDate) {
      return orderlist;
    }

    //to convert time to store timezone for ios, android and web
    const start = convertToTimeZone(startDate, currentStoreTimezone);

    //to convert time to store timezone for ios, android and web
    const end = convertToTimeZone(endDate, currentStoreTimezone);

    return orderlist?.filter((order) => {
      const orderDate = convertToTimeZone(
        order.createdAt,
        currentStoreTimezone
      );
      return orderDate >= start && orderDate <= end;
    });
  };

  useEffect(() => {
    setFilteredOrders(filteredOrdersByPage());
  }, [orderlist]);

  //fn to change the page no. in table
  const handleChangetwo = (value) => {
    setpage(value);
    setcurrentpage(value);
  };

  useEffect(() => {
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        getstorelist(token)
          .then((res) => {
            if (res.data.status === 200 || res.data.status === "200") {
              setStorelistData(res.data.message);
              setcurrentStore(res.data?.message[0]?.storeId);
              setCurrentStoreType(res.data?.message[0]?.type);
              setCurrentStoreTimezone(res.data?.message[0]?.timezone);
              setCurrentCurrency(res.data?.message[0]?.currency);
            } else {
              dispatch(setStoreListData([]));
            }
          })
          .catch((err) => {
            console.log("Error in Store list", err);
          });
      }
    });
  }, [dispatch]);

  useEffect(() => {
    setorderaccepted(true);
    setrefresh(!refresh);
  }, []);

  //fn to auto fetch orders list after a specific interval of time
  const updateState = useCallback(async () => {
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        const data = {
          storeId: currentStore,
          page: page,
          pageSize: size,
          startDate: new Date(startDate ?? new Date())?.toLocaleDateString(
            "en-US"
          ),
          endDate: new Date(endDate ?? new Date())?.toLocaleDateString("en-US"),
          timezone: currentStoreTimezone,
        };
        return axios
          .post(`${REACT_APP_ORDERAT}/order/list`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            setorderlist(
              res.data.order?.filter((item) => item.status !== "booked")
            );
            if (res.data?.count > size) {
              setPaginationLength(true);
            }
            // debugger
            if (res.data.count % size === 0) {
              setShowCount(res.data.count / size);
            } else {
              setShowCount(Math.floor(res.data.count / size) + 1);
            }
          })
          .catch((err) => {
            console.log("Error in Order list", err);
          });
      }
      return null;
    });
  }, [currentpage, size, currentStore, startDate, endDate]);

  useEffect(() => {
    AsyncStorage.getItem("token").then((token) => {
      const currentDate = new Date();
      const sevenDaysBefore = new Date();
      sevenDaysBefore.setDate(currentDate.getDate() - 6);
      axios
        .post(
          `${REACT_APP_ORDERAT}/order/analytics`,
          {
            storeId: currentStore,
            startDate: new Date(
              startDate ?? sevenDaysBefore
            )?.toLocaleDateString("en-US"),
            endDate: new Date(endDate ?? new Date())?.toLocaleDateString(
              "en-US"
            ),
            timeZone: currentStoreTimezone,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setReportOrderList(res.data.order[0]);
          setpage(1);
          setcurrentpage(1);
        })
        .catch((err) => {
          console.log("Error in Order list", err);
        });
    });
  }, [startDate, endDate, currentStore]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 5000);

  return (
    <Layout>
      <View style={tailwind("flex flex-col w-full h-full z-0")}>
        <View style={tailwind("w-full h-[60px] z-[100] mb-4")}>
          <ReportsHeader setcurrentStore={setcurrentStore} />
        </View>
        <ScrollView
          style={tailwind("")}
          contentContainerStyle={tailwind(
            `${Platform.OS !== "web" ? "pb-[80px]" : "h-full"}`
          )}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          {/* graphs */}

          <View style={tailwind("flex flex-col md:flex-row mb-4")}>
            {currentStoreType === "self" && (
              <>
                <View
                  style={[
                    tailwind(
                      "min-h-[398px] w-full md:flex-1 rounded-lg bg-white flex items-center justify-center"
                    ),
                    styles.shadow,
                  ]}
                >
                  {reportOrderList?.dateWiseOrders?.totalSalesVsDate?.length ? (
                    <>
                      <TotalSalesChart
                        analyticsData={reportOrderList?.dateWiseOrders}
                        currentStore={currentStore}
                        currentStoreTimezone={currentStoreTimezone}
                        currentCurrency={currentCurrency?.symbol}
                      />
                    </>
                  ) : (
                    <View>
                      <Text style={tailwind("font-poppins-bold text-lg")}>
                        {" "}
                        Total Sales
                      </Text>
                      <Text style={tailwind("font-poppins-regular text-lg")}>
                        {" "}
                        No Data to show{" "}
                      </Text>
                    </View>
                  )}
                </View>
                <View
                  style={[
                    tailwind(
                      "min-h-[398px] w-full md:flex-1 my-4 mx-0 md:mx-4 md:my-0 rounded-lg bg-white flex items-center justify-center"
                    ),
                    styles.shadow,
                  ]}
                >
                  {reportOrderList?.dateWiseOrders?.averageCartVsDate
                    ?.length ? (
                    <>
                      <AverageCartChart
                        analyticsData={reportOrderList?.dateWiseOrders}
                        currentStore={currentStore}
                        currentStoreTimezone={currentStoreTimezone}
                        currentCurrency={currentCurrency?.symbol}
                      />
                    </>
                  ) : (
                    <View>
                      <Text style={tailwind("font-poppins-bold text-lg")}>
                        {" "}
                        Average Cart Values
                      </Text>
                      <Text style={tailwind("font-poppins-regular text-lg")}>
                        {" "}
                        No Data to show{" "}
                      </Text>
                    </View>
                  )}
                </View>
              </>
            )}
            <View
              style={[
                tailwind(
                  "min-h-[398px] w-full md:flex-1 rounded-lg bg-white flex items-center justify-center"
                ),
                styles.shadow,
              ]}
            >
              {reportOrderList?.dateWiseOrders?.totalOrdersVsDate?.length ? (
                <>
                  <TotalOrdersChart
                    analyticsData={reportOrderList?.dateWiseOrders}
                    currentStore={currentStore}
                    currentStoreTimezone={currentStoreTimezone}
                  />
                </>
              ) : (
                <View>
                  <Text style={tailwind("font-poppins-bold text-lg")}>
                    {" "}
                    Total Orders
                  </Text>
                  <Text style={tailwind("font-poppins-regular text-lg")}>
                    {" "}
                    No Data to show{" "}
                  </Text>
                </View>
              )}
            </View>
          </View>

          {/* cards */}
          <View style={[tailwind("flex flex-col")]}>
            <View
              style={tailwind(
                "flex flex-col sm:flex-row justify-between items-center w-full  mb-4"
              )}
            >
              {reportOrderList?.uniqueCustomers?.totalUniqueCustomers && (
                <View
                  style={tailwind(
                    "flex w-full flex-col bg-white flex-1 h-full p-4 rounded-[7px] mb-4 sm:mb-0 sm:mr-8 "
                  )}
                >
                  <View style={tailwind("")}>
                    <Image
                      style={[tailwind(" w-[50px] h-[50px]")]}
                      contentFit="contain"
                      source={require("../assets/images/customers.svg")}
                    />
                    <Text style={tailwind("font-poppins-semiBold text-lg")}>
                      {" "}
                      Total Customer{" "}
                    </Text>
                    <Text
                      style={tailwind(
                        "text-colorSkyblue_100 font-poppins-semiBold"
                      )}
                    >
                      {" "}
                      {reportOrderList?.uniqueCustomers?.totalUniqueCustomers}
                    </Text>
                  </View>
                </View>
              )}

              {reportOrderList?.totalOrders && (
                <View
                  style={tailwind(
                    "flex flex-col bg-white w-full flex-1 h-full p-4 rounded-[7px]"
                  )}
                >
                  <View style={tailwind("")}>
                    <Image
                      style={[tailwind(" w-[50px] h-[50px]")]}
                      contentFit="contain"
                      source={require("../assets/images/orders.svg")}
                    />
                    {/* <img src="/assets/images/customers.svg" alt="" /> */}
                    <Text style={tailwind("font-poppins-semiBold text-lg")}>
                      {" "}
                      Total Orders
                    </Text>
                    <View>
                      {reportOrderList ? (
                        <>
                          <View
                            style={tailwind(
                              "flex flex-row justify-between items-center"
                            )}
                          >
                            <View>
                              <Text
                                style={tailwind(
                                  "font-poppins-semiBold text-colorSkyblue_100"
                                )}
                              >
                                {reportOrderList?.totalOrders?.ordersDelivered}
                                <Text
                                  style={tailwind(
                                    "font-poppins-medium text-black"
                                  )}
                                >
                                  {" "}
                                  Delivered
                                </Text>
                              </Text>
                            </View>
                            <View>
                              <Text
                                style={tailwind(
                                  "font-poppins-semiBold text-colorTomato_100"
                                )}
                              >
                                {reportOrderList?.totalOrders?.ordersCancelled}
                                <Text
                                  style={tailwind(
                                    "font-poppins-medium text-black"
                                  )}
                                >
                                  {" "}
                                  Cancelled
                                </Text>
                              </Text>
                            </View>
                          </View>
                        </>
                      ) : (
                        <Text> No Data to show</Text>
                      )}
                    </View>
                  </View>
                </View>
              )}
            </View>

            <View
              style={tailwind(
                "flex flex-col sm:flex-row justify-between items-center w-full  mb-4"
              )}
            >
              {currentStoreType === "self" && reportOrderList?.bestSeller && (
                <View
                  style={tailwind(
                    "flex flex-col w-full bg-white flex-1 h-full p-4 rounded-[7px] mb-4 sm:mb-0 sm:mr-8 "
                  )}
                >
                  <View style={tailwind("")}>
                    <Image
                      style={[tailwind(" w-[50px] h-[50px]")]}
                      contentFit="contain"
                      source={require("../assets/images/products.svg")}
                    />
                    <Text style={tailwind("font-poppins-semiBold text-lg")}>
                      {" "}
                      Best Seller{" "}
                    </Text>
                    <Text>
                      {" "}
                      {reportOrderList ? (
                        <Text
                          style={tailwind(
                            "text-colorSkyblue_100 font-poppins-semiBold"
                          )}
                        >
                          {reportOrderList?.bestSeller?.productName}
                        </Text>
                      ) : (
                        <Text>No data to show</Text>
                      )}{" "}
                    </Text>
                  </View>
                </View>
              )}

              {reportOrderList?.mostDeliveredLocation && (
                <View
                  style={tailwind(
                    "flex flex-col w-full bg-white flex-1 h-full p-4 rounded-[7px]"
                  )}
                >
                  <View style={tailwind("")}>
                    <Image
                      style={[tailwind(" w-[50px] h-[50px]")]}
                      contentFit="contain"
                      source={require("../assets/images/location.svg")}
                    />
                    <Text style={tailwind("font-poppins-semiBold text-lg")}>
                      Most Delivered Location
                    </Text>
                    <Text>
                      {" "}
                      {reportOrderList ? (
                        <Text
                          style={tailwind(
                            "text-colorSkyblue_100 font-poppins-semiBold"
                          )}
                        >
                          {" "}
                          {reportOrderList?.mostDeliveredLocation
                            ?.locationName &&
                            Object.values(
                              reportOrderList?.mostDeliveredLocation
                                ?.locationName
                            )[0]}
                        </Text>
                      ) : (
                        <Text>No data to show</Text>
                      )}{" "}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          </View>

          {/* table */}
          {/* <View
            style={[
              tailwind(
                `flex flex-col bg-white p-4 rounded-[7px] ${
                  Dimensions.get("window").width < 640
                    ? "min-h-[250px]"
                    : "min-h-[calc(100%-450px)]"
                }`
              ),
            ]}
          > */}
          <View
            style={[
              tailwind(
                `flex flex-col bg-white p-4 rounded-[7px] ${
                  Platform.OS === "web" && Dimensions.get("window").width < 870
                    ? "mb-[4%]"
                    : ""
                } `
              ),
            ]}
          >
            <View
              style={tailwind(
                "flex flex-row justify-between h-[60px] items-center mb-4"
              )}
            >
              <Text style={tailwind("font-poppins-bold text-[22px]")}>
                Orders List
              </Text>
              <Pressable
                style={tailwind(
                  "px-4 py-2 bg-colorSkyblue_100 flex flex-row justify-center items-center rounded-md"
                )}
                onPress={handleExportClick}
              >
                <Image
                  style={[tailwind(" w-[20px] h-[20px] mr-2")]}
                  contentFit="contain"
                  source={require("../assets/images/Export_excel.svg")}
                />
                <Text
                  style={tailwind("font-poppins-regular text-white text-base")}
                >
                  Export Excel
                </Text>
              </Pressable>
            </View>
            {filteredOrders && filteredOrders.length > 0 ? (
              <Table
                filteredOrders={filteredOrders}
                currentStore={currentStore}
                currentStoreType={currentStoreType}
                currentStoreTimezone={currentStoreTimezone}
                paginationLength={paginationLength}
                showCount={showCount}
                size={size}
                page={page}
                handleChangetwo={handleChangetwo}
              />
            ) : (
              <View
                style={tailwind(
                  "flex justify-center items-center flex-1 min-h-[100px] my-auto"
                )}
              >
                <Text style={tailwind("font-poppins-regular text-base")}>
                  No Orders Available
                </Text>
              </View>
            )}
          </View>
        </ScrollView>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  shadow: {
    shadowColor: "#000", // Shadow color
    shadowOffset: { width: 0, height: 0 }, // Offset for shadow
    shadowOpacity: 0.12, // Opacity of the shadow
    shadowRadius: 10, // Radius of the shadow blur
    // elevation: 5, // Elevation for Android
  },
});

export default Reports;
