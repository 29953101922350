import { createSlice } from "@reduxjs/toolkit"

const UserSlice = createSlice({
  name: "user",
  initialState: {
    token: "",
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    
  }
})

export const {setToken} = UserSlice.actions;
export default UserSlice.reducer;