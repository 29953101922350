import * as React from "react";
import { View } from "react-native";
import { useTailwind } from "tailwind-rn";
import Title from "./Title";
import Sidebar from "./Sidebar";
import StoreBtn from "./StoreBtn";

const SidebarMobile = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind("w-[80%] xsm:w-[60%] h-full bg-colorWhite")}>
      <View
        style={tailwind(
          "h-[30%] min-h-[185px] border-b border-b-gray-300 flex justify-center px-4 py-3"
        )}
      >
        <Title />
        <StoreBtn />
      </View>
      <Sidebar/>
    </View>
  );
};

export default SidebarMobile;
