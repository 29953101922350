import { createSlice } from "@reduxjs/toolkit"

const DateFilterSlice = createSlice({
  name: "dateFilter",
  initialState: {
    startDate: new Date().toLocaleDateString('en-US'),
    endDate: new Date().toLocaleDateString('en-US')
,
  },
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload
    },
    
  }
})

export const {setStartDate, setEndDate} = DateFilterSlice.actions;
export default DateFilterSlice.reducer;