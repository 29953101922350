import { Dimensions, FlatList, Text, View } from "react-native";
import Layout from "./Layout";
import { useCallback, useEffect, useRef, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useTailwind } from "tailwind-rn";
import { summonRobot } from "../api";
import { useSelector } from "react-redux";

const REACT_APP_APIOTTONOMY_URL = "https://testing.ottonomy.io/";

const Ottobots = () => {
  const tailwind = useTailwind();
  const selectedFleet = useSelector((state) => state.store.selectedStore.fleetId)
  const [robotData, setRobotData] = useState([]);
  const [currentStorePickupLocation, setcurrentStorePickupLocation] = useState();
  const [groupsAssignedToRobots, setGroupsAssignedToRobots] = useState({});

  const columns =
    Dimensions.get("window").width <= 870
      ? 1
      : 2;

  function parseItem(item) {
    const [, stringPart = "", numberPart = 0] =
      /(^[a-zA-Z]*)(\d*)$/.exec(item) || [];
    return [stringPart, numberPart];
  }

  const updateRobotETA = useCallback(async () => {
    console.log('fleet called')
    AsyncStorage.getItem("token").then(async (token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        console.log(selectedFleet, 'fleet 2222')

        if (selectedFleet && token) {

          console.log("token inside ETA", token)
          return await axios
            .post(
              `${REACT_APP_APIOTTONOMY_URL}getRobotsETA/${selectedFleet}`
            )
            .then((res) => {
              setRobotData(
                res.data.result.sort((a, b) => {
                  const [stringA, numberA] = parseItem(a.robotId);
                  const [stringB, numberB] = parseItem(b.robotId);
                  //   const comparison = stringA.localeCompare(stringB);
                  console.log(stringA, stringB, "hello");
                  const comparison = 0;
                  return comparison === 0
                    ? Number(numberA) - Number(numberB)
                    : comparison;
                })
              );
            })
            .catch((err) => {
              console.error(err);
              // toast.error("Something went wrong")
            });
        }
      }
    });
    console.log("token outside ETA")
    return null;
    // console.log("Result",res.data.result.sort((a,b) => a.robotId - b.robotId))
  }, [selectedFleet]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateRobotETA, 3000);

  const getRobotBox = (robot, index) => {
    return (
      <View
      key={"robot__no__" + index}
      style={tailwind(
        "flex flex-col w-[95%] md:w-[45%] mx-[2.5%] px-4 py-3 border rounded-lg shadow-white gap-y-2"
      )}
    >
      <View style={tailwind('flex flex-row justify-between')}>
        <View style={tailwind("flex flex-col leading-[19px] flex-shrink")}>
          <Text style={tailwind("flex font-semibold text-[#212529]")}>
            {robot?.robotId}
          </Text>
          <Text
            style={tailwind(
              "text-xs font-semibold text-colorSkyblue_100"
            )}
          >
            {robot?.robotStatus === "AVAILABLE" &&
              !robot?.currentRobotAction &&
              (robot?.nearestLocation !==
              Object.values(currentStorePickupLocation)[0] ? (
                <>
                  {`Available ${
                    robot?.nearestLocation
                      ? "near " + robot?.nearestLocation
                      : ""
                  }`}
                  <div
                    className="flex items-center gap-1 border-b border-[#E96C67] w-fit cursor-pointer"
                    onClick={async () => {
                      // setloaderstate(true);
                      let res = await summonRobot(
                        currentStore,
                        robot?.robotId
                      );
                      if (res.status === 200) {
                        updateRobotETA();
                      //   setloaderstate(false);
                      //   toast.success("Robot Called Successfully");
                      } else {
                      //   setloaderstate(false);
                      //   toast.error("Something Went Wrong");
                      }
                    }}
                  >
                    {/* <MyLocationOutlinedIcon className="w-3 h-3 cursor-pointer text-[#E96C67]" /> */}
                    <span className="text-[10px] font-semibold text-[#E96C67]">
                      {`Call Robot To ${
                        Object.values(currentStorePickupLocation)[0]
                      }`}
                    </span>
                  </div>
                </>
              ) : (
                `Available ${
                  robot?.nearestLocation
                    ? "near " + robot?.nearestLocation
                    : ""
                }`
              ))}
            {robot?.robotStatus === "UNAVAILABLE" &&
              `Unavailable ${
                robot?.nearestLocation
                  ? "near " + robot?.nearestLocation
                  : ""
              }`}
            {robot?.robotStatus === "AVAILABLE" &&
              robot?.currentRobotAction &&
              robot?.currentRobotAction?.actionPriority === 0 &&
              `Ready to be dispatched`}
            {robot?.robotStatus === "AVAILABLE" &&
              robot?.currentRobotAction &&
              robot?.currentRobotAction?.message === "MOVELOAD" &&
              robot?.currentRobotAction?.actionPriority === 1 &&
              `Enroute to pickup ${
                robot?.currentRobotAction?.locationName
                  ? "from " + robot?.currentRobotAction?.locationName
                  : ""
              }`}
            {robot?.robotStatus === "AVAILABLE" &&
              robot?.currentRobotAction &&
              robot?.currentRobotAction?.message === "EXPECTLOAD" &&
              robot?.currentRobotAction?.actionPriority === 1 &&
              `Waiting pickup ${
                robot?.currentRobotAction?.locationName
                  ? "at " + robot?.currentRobotAction?.locationName
                  : ""
              }`}
            {robot.robotStatus === "AVAILABLE" &&
              robot?.currentRobotAction &&
              robot?.currentRobotAction?.message === "MOVEUNLOAD" &&
              robot?.currentRobotAction?.actionPriority === 1 &&
              `Enroute to dropoff ${
                robot?.currentRobotAction?.locationName
                  ? "at " + robot?.currentRobotAction?.locationName
                  : ""
              }`}
            {robot?.robotStatus === "AVAILABLE" &&
              robot?.currentRobotAction &&
              robot?.currentRobotAction?.message === "EXPECTUNLOAD" &&
              `Waiting dropoff ${
                robot?.currentRobotAction?.locationName
                  ? "at " + robot?.currentRobotAction?.locationName
                  : ""
              }`}
            {robot?.robotStatus === "AVAILABLE" &&
              robot?.currentRobotAction &&
              robot?.currentRobotAction?.message === "MOVEWANDER" &&
              robot?.currentRobotAction?.actionPriority === 1 &&
              `Moving ${
                robot?.currentRobotAction?.locationName
                  ? "towards " +
                    robot?.currentRobotAction?.locationName
                  : ""
              }`}
            {robot?.robotStatus === "AVAILABLE" &&
              robot?.currentRobotAction &&
              robot?.currentRobotAction?.message === "MOVESUMMON" &&
              robot?.currentRobotAction?.actionPriority === 1 &&
              `Moving ${
                robot?.currentRobotAction?.locationName
                  ? "towards " +
                    robot?.currentRobotAction?.locationName
                  : ""
              }`}
            {robot?.robotStatus === "AVAILABLE" &&
              robot?.currentRobotAction &&
              robot?.currentRobotAction?.actionPriority === 1 &&
              robot?.currentRobotAction?.message === "MOVEHOME" &&
              `Enroute to home ${
                robot?.currentRobotAction?.locationName
                  ? "(" +
                    robot?.currentRobotAction?.locationName +
                    ")"
                  : ""
              }`}
          </Text>
        </View>
        {robot.robotStatus === "AVAILABLE" &&
        robot.ETA &&
        robot.ETA !== 0 ? (
          <View style={tailwind("flex flex-col items-center")}>
            <Text style={tailwind("text-[11px] font-semibold text-black")}>
              Back in
            </Text>
            <View style={tailwind("flex flex-row items-end text-black")}>
              <Text style={tailwind("text-xl font-semibold leading-[21px] mr-1")}>
                {Math.ceil(robot.ETA / 60)}
              </Text>
              <Text style={tailwind("text-xs font-extralight")}>MINS</Text>
            </View>
          </View>
        ) : null}
      </View>
      {/* need to add this from admin dashboard of prev code */}
      {/* <div className="flex flex-wrap gap-x-1">
            {groupsAssignedToRobots[robot.robotId] && Object.values(groupsAssignedToRobots[robot.robotId]).map((group, index) => (
                <div style={{ backgroundColor: groupsColor[group] }} className="w-1/6 h-1.5 rounded" key={index} />
              ))}
         
      </div> */}
    </View>
    )
  }

  return (
    <Layout>
      <View
        style={tailwind(
          "bg-white h-[94%] md:h-full p-4 rounded-[7px] w-full pb-[60px] md:pb-[0px]"
        )}
      >
        <Text style={tailwind("font-poppins-bold text-xl")}>Ottobots</Text>

        {robotData && robotData.length !== 0 ? (
          <View
            style={tailwind("flex flex-col w-full overflow-x-auto gap-x-3")}
          >
            <FlatList
                data={robotData}
                numColumns={columns}
                renderItem={(item) => getRobotBox(item.item, item.index)}
                // style={tailwind("h-[calc(100%-50px)] mt-4 w-full")}
                style={tailwind("h-full mt-4 w-full")}
                contentContainerStyle={tailwind("")}
                ItemSeparatorComponent={() => <View style={{ height: 20 }} />}
                keyExtractor={(item) => item?._id}
              />
           
          </View>
        ) : (
          <View
            style={tailwind("flex items-center justify-center w-full h-full ")}
          >
            <Text
              style={tailwind(
                "text-[rgb(255,255,255,0.7)] font-poppins-regular"
              )}
            >
              No Ottobots to show
            </Text>
          </View>
        )}
      </View>
    </Layout>
  );
};

export default Ottobots;
