const Stack = createNativeStackNavigator();
import * as React from "react";
import { TailwindProvider, useTailwind } from "tailwind-rn";
import utilities from "./tailwind.json";
import { NavigationContainer, useLinkTo } from "@react-navigation/native";
import { useFonts } from "expo-font";
import Toast from "react-native-toast-message";
import { Provider } from "react-redux";
import { PaperProvider } from "react-native-paper";

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  Text,
} from "react-native";

import { Login } from "./screens/Login";
import OrderDashboard from "./screens/OrderDashboard";
import Stores from "./screens/Stores";
import Reports  from "./screens/Reports";
import Ottobots from "./screens/Ottobots";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { store } from "./utils/redux/store";
import Items from "./screens/Items";
import { SafeAreaProvider } from 'react-native-safe-area-context';

const App = () => {
  const [hideSplashScreen, setHideSplashScreen] = React.useState(true);
  const [initialRouteName, setInitialRouteName] = React.useState("OrderDashboard");
  const [showLoader, setShowLoader] = React.useState(false);
  const tailwind = useTailwind();

  const [fontsLoaded, error] = useFonts({
    poppinsRegular: require("./assets/fonts/Poppins-Regular.ttf"),
    poppinsMedium: require("./assets/fonts/Poppins-Medium.ttf"),
    poppinsSemiBold: require("./assets/fonts/Poppins-SemiBold.ttf"),
    poppinsBold: require("./assets/fonts/Poppins-Bold.ttf"),
    russoOneRegular: require("./assets/fonts/RussoOne-Regular.ttf"),
  });

  if (!fontsLoaded && !error) {
    return null;
  }

  AsyncStorage.getItem("token").then((token) => {
    if (!token === null || token === "null" || token === undefined || !token) {
      setInitialRouteName("Layout");
    }
  });

  const config = {
    screens: {
      Login: "login",
      OrderDashboard: "orderDashboard",
      Stores: "stores",
      Reports: "reports",
      Ottobots: "ottobots",
      Layout: "dashboard",
      Items: 'items/:storeId'
    },
  };

  const linking = {
    prefixes: [""],
    config,
  };

  return (
    <SafeAreaProvider>

      <Provider store={store}>
        <TailwindProvider utilities={utilities}>
          <PaperProvider>
            <NavigationContainer
              linking={linking}
              fallback={<Text>Loading...</Text>}
            >
              {/* {hideSplashScreen ? ( */}
              <Stack.Navigator
                initialRouteName={initialRouteName}
                // screenOptions={{ headerShown: false, contentStyle: {backgroundColor:"#e4e7eb", paddingTop: StatusBar.currentHeight? StatusBar.currentHeight : Platform.OS !== 'web'?20:0}, header: () => <></> }}
              >
                <Stack.Screen
                  name="Login"
                  component={Login}
                  options={{ headerShown: false }}
                />
                <Stack.Screen
                  name="OrderDashboard"
                  component={() => <OrderDashboard setShowLoader={setShowLoader} showLoader={showLoader} />}
                  // component={OrderDashboard}
                  
                  options={{ headerShown: false, title: "" }}
                />
                <Stack.Screen
                  name="Stores"
                  component={() => <Stores setShowLoader={setShowLoader} showLoader={showLoader} />}
                  options={{ headerShown: false, title: "" }}
                />
                <Stack.Screen
                  name="Reports"
                  component={() => <Reports setShowLoader={setShowLoader} showLoader={showLoader} />}
                  options={{ headerShown: false, title: "" }}
                />
                <Stack.Screen
                  name="Ottobots"
                  component={() => <Ottobots setShowLoader={setShowLoader} showLoader={showLoader} />}
                  options={{ headerShown: false, title: "" }}
                />
                <Stack.Screen
                  name="Items"
                  component={() => <Items setShowLoader={setShowLoader} showLoader={showLoader} />}
                  options={{ headerShown: false, title: "" }}
                />
              </Stack.Navigator>

              {/* ): null} */}
            </NavigationContainer>
          </PaperProvider>

          {/* <Login/> */}
          <Toast />
        </TailwindProvider>
      </Provider>
    </SafeAreaProvider>
  );
};
export default App;
