import AsyncStorage from "@react-native-async-storage/async-storage";
import { Image } from "expo-image";
import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import {
    Checkbox,
  DataTable,
  Modal,
  Portal,
  Switch,
  Text,
  Tooltip,
} from "react-native-paper";
import { useTailwind } from "tailwind-rn";
import { setstorestatus } from "../api";
import { useDispatch, useSelector } from "react-redux";
import StoreClosePopup from "./StoreClosePopup";

const StoresTable = ({ storelist, handleStoreClick }) => {
  const tailwind = useTailwind();
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const showToast = ({ type, text }) => {
    Toast.show({
      type: type,
      text1: text,
    });
  };

  //variables for table width according to the width of the window to make it responsive across all devices
  const factor = Dimensions.get("window").width >= 870 ? 165 : 0;
  const tableMaxWidth =
    Dimensions.get("window").width - factor >= 950
      ? Dimensions.get("window").width - factor
      : 950;

  const changeStoreStatus = (e, item) => {
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        const today = new Date();
        setstorestatus(
          "" + Math.abs(item.status - 1),
          today.toISOString(),
          item.status ? checked : "",
          token,
          item.storeId
        ).then(() => {
          dispatchIsStoreActive();
        });
      }
    });

    hideModal();
  };

  const dispatchIsStoreActive = () => {
    showToast({ type: "success", text: "Login Successful" });
    showToast({ type: "error", text: "Login Failed" });
  };

  const [modalChild, setModalChild] = useState(
    <StoreClosePopup
      dispatchIsStoreActive={dispatchIsStoreActive}
      hideModal={hideModal}
    />
  );

  const containerStyle = {
    backgroundColor: "white",
    zIndex: 100,
    borderRadius: 10,
  };

  const modalStyles = {
    zIndex: 100,
    width: Platform.OS === "web" ? "500px" : "10px",
    marginHorizontal: "auto",
  };

  if (Platform.OS !== "web") {
    modalStyles.marginHorizontal = 10;
  }

  return (
    <View style={tailwind("w-full")}>
      <Portal>
        <Modal
          visible={visible}
          onDismiss={hideModal}
          contentContainerStyle={containerStyle}
          style={modalStyles}
        >
          {modalChild}
        </Modal>
      </Portal>
      {storelist?.length > 0 ? (
        <>
          {/* Table */}

          <ScrollView
            horizontal
            style={tailwind("")}
            contentContainerStyle={[tailwind(``), { width: tableMaxWidth }]}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            <View style={tailwind("w-full")}>
              {/* table header */}
              <View
                style={tailwind("bg-[#E9EFF6] border-b-0 flex flex-row w-full")}
              >
                <View style={tailwind("flex-1 flex  justify-center p-4")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    Name
                  </Text>
                </View>
                <View style={tailwind("w-[200px] flex justify-center p-4")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    Zone
                  </Text>
                </View>
                <View style={tailwind("w-[150px] flex justify-center p-4")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    {" "}
                    Owner
                  </Text>
                </View>
                <View style={tailwind("w-[100px]  flex  justify-center p-4")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    {" "}
                  </Text>
                </View>
                <View style={tailwind("w-[150px] flex  justify-center p-4")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    {" "}
                  </Text>
                </View>
              </View>

              {/* rendering table rows from filteredorders */}
              {storelist?.map((item, index) => {
                return (
                  <View
                    key={index}
                    style={tailwind("border-b-0 flex flex-row w-full")}
                  >
                    <Pressable
                      style={tailwind(
                        "flex-1 flex flex-row p-4"
                      )}
                      onPress={() => handleStoreClick(item)}
                    >
                      <Image
                        style={tailwind("w-[60px] h-[60px] mr-4")}
                        contentFit="cover"
                        source={item.image}
                      />
                      <View style={tailwind("flex flex-col flex-shrink my-auto")}>
                        <Text
                          style={tailwind(
                            "text-colorSkyblue_100 text-base font-poppins-semiBold"
                          )}
                        >
                          {item.name && Object.values(item.name)[0]}
                        </Text>
                        <Text
                          style={tailwind(
                            "text-black text-sm font-poppins-regular"
                          )}
                        >
                          {item.description &&
                            Object.values(item.description)[0]}
                        </Text>
                      </View>
                    </Pressable>
                    <View
                      style={tailwind("w-[200px] flex  p-4 justify-center")}
                    >
                      <Text
                        style={tailwind(
                          "text-black text-sm font-poppins-regular"
                        )}
                      >
                        {item.location && Object.values(item.location)[0]}{" "}
                      </Text>
                    </View>

                    <View
                      style={tailwind("w-[150px] flex  p-4 justify-center")}
                    >
                      <Text
                        style={tailwind(
                          "text-colorSkyblue_100 text-sm font-poppins-regular"
                        )}
                      >
                        {item.storeManager.name
                          ? item.storeManager.name
                          : "Not Assigned"}
                      </Text>
                    </View>
                    <View
                      style={tailwind(
                        "w-[100px] flex  p-4 justify-center"
                      )}
                    >
                      <Pressable
                        style={[tailwind("")]}
                        onPress={(e) => {
                          if (item.status) {
                            setModalChild(
                              <StoreClosePopup
                                dispatchIsStoreActive={dispatchIsStoreActive}
                                hideModal={hideModal}
                                selectedStoreTemp={item}
                              />
                            );
                            showModal();
                          } else {
                            changeStoreStatus(e, item);
                          }
                        }}
                      >
                        <View
                          style={[
                            tailwind(
                              `w-[50px] ml-[20px] items-center flex-row p-1 rounded-2xl ${
                                item.status
                                  ? "bg-colorLimegreen_100"
                                  : "bg-colorTomato_100"
                              }`
                            ),
                          ]}
                        >
                          <Image
                            style={[
                              tailwind(
                                `w-4 h-4 mt-0.5 rounded-xl ${
                                  item.status ? "ml-auto" : "mr-auto"
                                } `
                              ),
                            ]}
                            contentFit="contain"
                            source={require("../assets/ellipse-2.png")}
                          />
                        </View>
                      </Pressable>
                      {/* <Switch value={isSwitchOn} onValueChange={onToggleSwitch} theme={"version-3"} /> */}
                    </View>

                    <View
                      style={tailwind(
                        "w-[150px] p-4 justify-center flex flex-row items-center mb-2 font-poppins-regular relative"
                      )}
                    >
                      {/* <View style={tailwind(`${(Platform.OS === "web" )? "" : "border border-black absolute w-[24px] h-[24px] left-[6px]"}`)}></View> */}
                      <Checkbox.Android
                        status={"checked"}
                        color="blue"
                        // uncheckedColor="blue"
                        onPress={() =>
                         console.log('clicked')
                        }
                      />
                      <Text
                        style={tailwind(
                          "ml-2 font-poppins-regular flex-shrink text-black"
                        )}
                      >
                        Hide when Close
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </ScrollView>
        </>
      ) : (
        <Text style={tailwind("font-poppins-regular text-xl text-black text-center my-auto")}>No Stores available</Text>
      )}
    </View>
  );
};

export default StoresTable;
