import { createSlice } from "@reduxjs/toolkit"

const OrdersSlice = createSlice({
  name: "orders",
  initialState: {
    newOrdersCount: 0,
    ordersData: [],
    currentOrdersData: [],
    pastOrdersData: []
  },
  reducers: {
    setNewOrdersCount: (state, action) => {
      state.newOrdersCount = action.payload
    },
    setOrdersData: (state, action) => {
      state.ordersData = action.payload
    },
    setCurrentOrdersData: (state, action) => {
      state.currentOrdersData = action.payload
    },
    setPastOrdersData: (state, action) => {
      state.pastOrdersData = action.payload
    },
  }
})

export const {setNewOrdersCount, setOrdersData, setCurrentOrdersData, setPastOrdersData} = OrdersSlice.actions;
export default OrdersSlice.reducer;