import React, { useEffect, useState } from "react";
import {
  Button,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Image } from "expo-image";
import { useTailwind } from "tailwind-rn";
import { Link, useLinkTo } from "@react-navigation/native";
import { login } from "../api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Toast from "react-native-toast-message";
import { useSelector, useDispatch } from "react-redux";
import { setToken as setTokenRedux } from "../utils/redux/slice/UserSlice";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = React.useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const linkTo = useLinkTo();
  const tailwind = useTailwind();

  const showToast = ({ type, text }) => {
    Toast.show({
      type: type,
      text1: text,
    });
  };

  AsyncStorage.getItem("token").then((token) => {
    if (
      !(token === null || token === "null" || token === undefined || !token)
    ) {
      setTimeout(() => linkTo("/orderDashboard"), 100);
    }
  });

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassward = () => {
    return password.length >= 8;
  };

  const _onLoginPressed = (e) => {
    if (validateEmail() && validatePassward()) {
      login({ email, password })
        .then(async (res) => {
          await AsyncStorage.setItem("token", res.data.message.token);
          await AsyncStorage.setItem("userId", res.data.message.email);
          dispatch(setTokenRedux(res.data.message.token));

          if (res.data.message.role) {
            await AsyncStorage.setItem("role", res.data.message.role);
          }

          linkTo("/orderDashboard");
          showToast({ type: "success", text: "Login Successful" });
        })
        .catch((err) => {
          console.log(err);
          showToast({ type: "error", text: "Login Failed" });
        });
    }
  };

  return (
    <ScrollView
      contentContainerStyle={tailwind("flex-grow justify-center")}
      style={tailwind("flex-1")}
      keyboardShouldPersistTaps="handled"
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      <View
        style={[
          tailwind(
            "flex justify-center items-center flex-col min-h-screen lg:min-h-full lg:flex-row justify-center overflow-y-scroll "
          ),
        ]}
      >
        <View
          style={[
            tailwind(
              "bg-login-gradient w-full lg:w-[50%] h-[100vh] lg:h-screen hidden lg:block"
            ),
            { backgroundColor: "#40B4D0" },
          ]}
        >
          <View style={tailwind("mt-[44px]")}>
            <Image
              style={[tailwind(" w-[200px] h-[200px]")]}
              contentFit="contain"
              source={require("../assets/images/Login_page_background_image.png")}
            />
          </View>
          <View
            style={[
              tailwind("text-start w-[60%] bottom-[154px] left-[129px]"),
              { position: "absolute" },
            ]}
          >
            <Text style={[tailwind("text-4xl text-white font-normal mb-4")]}>
              Autonomous Delivery Robots
            </Text>
            <Image
              style={[tailwind(" w-[89px] h-[89px]")]}
              contentFit="contain"
              source={require("../assets/images/Image_Login_page.png")}
            />
          </View>
        </View>

        <View
          style={tailwind(
            "w-full lg:w-[50%] flex justify-center px-[2rem] md:py-0 sm:px-[10rem] lg:px-[3rem]"
          )}
        >
          <View style={tailwind("w-full")}>
            <Image
              style={[tailwind("w-[22%] h-[85px] md:w-[30%]")]}
              contentFit="contain"
              source={require("../assets/images/ottonomy-logo-new.png")}
            />
          </View>
          <Text style={tailwind("m-auto text-start text-[26px] mt-[30px]")}>
            Let's Get Started
          </Text>
          <TextInput
            enterKeyHint="next"
            value={email}
            onChangeText={(text) => {
              setEmail(text);
            }}
            autoCapitalize="none"
            textContentType="emailAddress"
            placeholder="Email id"
            placeholderTextColor="#9ba3a9"
            inputMode="email"
            style={[
              tailwind(
                "w-full mx-auto bg-gray-200 rounded-lg mt-6 block px-4 py-3 text-[14px] font-medium text-gray-900 placeholder-gray-500 border border-transparent rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              ),
              { backgroundColor: "#E9EFF6" },
            ]}
          />
          <View style={tailwind("relative")}>
            <TextInput
              value={password}
              id="password"
              returnKeyType="done"
              onChangeText={(text) => {
                setPassword(text);
              }}
              placeholder="Password"
              placeholderTextColor="#9ba3a9"
              secureTextEntry={!showPassword}
              style={[
                tailwind(
                  "w-full mx-auto bg-gray-200 rounded-lg mt-6 block px-4 py-3 text-[14px] font-medium text-gray-900 placeholder-gray-500 border border-transparent rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                ),
                { backgroundColor: "#E9EFF6" },
              ]}
            />
            <TouchableOpacity
              onPress={() => setShowPassword((prev) => !prev)}
              style={[
                tailwind("absolute top-[55%] right-[16px]"),
                { position: "absolute" },
              ]}
            >
              <View>
                {showPassword ? (
                  <Image
                    style={[tailwind("w-[20px] h-[20px]")]}
                    contentFit="contain"
                    source={require(`../assets/images/view.png`)}
                  />
                ) : (
                  <Image
                    style={[tailwind("w-[20px] h-[20px]")]}
                    contentFit="contain"
                    source={require(`../assets/images/hidden.png`)}
                  />
                )}
              </View>
            </TouchableOpacity>
          </View>
          <View style={tailwind("w-full items-end mb-[34px] mt-[20px]")}>
            <TouchableOpacity>
              <Text
                style={[
                  tailwind("font-light text-sm underline"),
                  {
                    color: "#969696",
                  },
                ]}
              >
                Forgot your password?
              </Text>
            </TouchableOpacity>
          </View>
          <View style={tailwind("")}>
            <Pressable
              onPress={_onLoginPressed}
              style={[
                tailwind("bg-blue-500 w-full p-[16px] rounded-lg"),
                { backgroundColor: "#3CB0CC" },
              ]}
            >
              <Text
                style={tailwind(
                  "w-full text-center text-white font-bold text-[16px]"
                )}
              >
                SignIn
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};
