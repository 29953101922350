import { Image } from "expo-image";
import { Pressable, Text, View } from "react-native";
import { useTailwind } from "tailwind-rn";
import Title from "./Title";
import StoreBtn from "./StoreBtn";

const AppHeader = ({ setShowSidebarMenu }) => {
  const tailwind = useTailwind();

  return (
    <View
      style={[
        tailwind(
          "w-full mb-4 h-[60px] sm:h-[74px] px-2 flex justify-between items-center flex-row rounded-[10px] bg-colorWhite"
        ),
      ]}
    >
      {/* Mobile Logo */}
      <View style={tailwind("flex sm:hidden justify-center w-[55px] h-full")}>
        <Image
          style={[tailwind("w-full h-[50px]")]}
          contentFit="contain"
          source={require("../assets/images/Image_Login_page.png")}
        />
      </View>

      {/* <Title/> */}
      <View style={tailwind("hidden sm:flex")}>
        <Title />
      </View>

      {/* Manage Orders Title */}
      <Text
        style={[
          tailwind(" font-bold text-xl sm:text-2xl font-poppins-bold"),
          { color: "#454545" },
        ]}
      >
        Manage Orders
      </Text>

      {/* Store Button */}
      <View style={tailwind("hidden sm:flex")}>
        <StoreBtn />
      </View>

      <Pressable
        style={tailwind(" w-[30px] h-full flex sm:hidden justify-center")}
        onPress={() => {
          setShowSidebarMenu(true);
        }}
      >
        <Image
          style={[tailwind("w-full h-[50px]")]}
          contentFit="contain"
          source={require("../assets/Hamburger.png")}
        />
      </Pressable>
    </View>
  );
};

export default AppHeader;
