import { createSlice } from "@reduxjs/toolkit";

const StoreSlice = createSlice({
  name: "store",
  initialState: {
    selectedStore: {},
    isStoreActive: {},
    storeListData: [],
  },
  reducers: {
    setSelectedStore: (state, action) => {
      state.selectedStore = action.payload;
      state.isStoreActive = action.payload.status == 1;
    },
    setIsStoreActive: (state, action) => {
      state.isStoreActive = action.payload;
    },
    setStoreListData: (state, action) => {
      state.storeListData = action.payload;
    },
  },
});

export const { setSelectedStore, setIsStoreActive, setStoreListData } =
  StoreSlice.actions;
export default StoreSlice.reducer;
