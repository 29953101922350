import * as React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { Color, Padding, Border, FontSize, FontFamily } from "../GlobalStyles";
import { Image } from "expo-image";
import { useTailwind } from "tailwind-rn";
import { useLinkTo, useRoute } from "@react-navigation/native";
import { useSelector } from "react-redux";

const BottomNavigation = () => {
  const tailwind = useTailwind();
  const linkTo = useLinkTo();
  const route = useRoute();
  const newOrdersCount = useSelector((state) => state?.orders?.newOrdersCount);

  const [activeTab, setActiveTab] = React.useState(route.name);
  React.useEffect(() => {
    setActiveTab(() => route.name);
  }, [route]);

  const handleNavigation = (destination) => {
    linkTo(`/${destination}`);
  };

  return (
    <View
      style={tailwind(
        "min-h-[55px] bg-colorWhite mt-auto flex flex-row justify-around items-center px-4 sm:px-8 py-4 md:hidden "
      )}
    >
      {/* Orders */}
      <Pressable
        style={[tailwind("w-fit")]}
        onPress={() => {
          handleNavigation("orderDashboard");
        }}
      >
        <View style={tailwind("flex flex-row justify-center items-center")}>
          {activeTab === "OrderDashboard" ? (
            <View
              style={tailwind(
                "flex flex-row justify-center items-center bg-colorSkyblue_100 px-6 py-2 rounded-3xl relative"
              )}
            >
              <View
                style={[
                  tailwind(
                    " min-w-[22px] min-h-[18px] absolute rounded-full p-1 bg-red-500 text-center right-[0px] top-[-10px] z-[200]"
                  ),
                ]}
              >
                <Text
                  style={[
                    tailwind(
                      "text-white font-poppins-bold text-[10px] text-center"
                    ),
                  ]}
                >
                  {newOrdersCount}
                </Text>
              </View>

              <Image
                style={tailwind("w-[21px] h-[21px]")}
                contentFit="cover"
                source={require("../assets/Orders-Active.svg")}
              />
              <Text
                style={tailwind(
                  "text-colorDarkslategray_200 text-center text-sm font-semibold ml-2  text-colorWhite"
                )}
              >
                Orders
              </Text>
            </View>
          ) : (
            <View style={tailwind("relative")}>
              <View
                style={[
                  tailwind(
                    " min-w-[22px] text-center min-h-[18px] absolute rounded-full p-1 bg-red-500 right-[-10px] top-[-12px] z-[200]"
                  ),
                ]}
              >
                <Text
                  style={[
                    tailwind(
                      "text-white font-poppins-bold text-[10px] text-center"
                    ),
                  ]}
                >
                  {newOrdersCount}
                </Text>
              </View>
              <Image
                style={tailwind("w-[21px] h-[21px]")}
                contentFit="cover"
                source={require("../assets/Orders-InActive.svg")}
              />
            </View>
          )}
        </View>
      </Pressable>

      {/* Stores */}
      <Pressable
        style={[tailwind("w-fit")]}
        onPress={() => {
          handleNavigation("stores");
        }}
      >
        <View style={tailwind("flex flex-row justify-center items-center")}>
          {activeTab === "Stores" ? (
            <View
              style={tailwind(
                "flex flex-row justify-center items-center bg-colorSkyblue_100 px-6 py-2 rounded-3xl"
              )}
            >
              <Image
                style={tailwind("w-[21px] h-[21px]")}
                contentFit="cover"
                source={require("../assets/Stores-Active.png")}
              />
              <Text
                style={tailwind(
                  "text-colorDarkslategray_200 text-center text-sm font-semibold ml-2  text-colorWhite"
                )}
              >
                Stores
              </Text>
            </View>
          ) : (
            <Image
              style={tailwind("w-[21px] h-[21px]")}
              contentFit="cover"
              source={require("../assets/Stores-InActive.svg")}
            />
          )}
        </View>
      </Pressable>

      {/* Reports */}
      <Pressable
        style={[tailwind("w-fit")]}
        onPress={() => {
          handleNavigation("reports");
        }}
      >
        <View style={tailwind("flex flex-row justify-center items-center")}>
          {activeTab === "Reports" ? (
            <View
              style={tailwind(
                "flex flex-row justify-center items-center bg-colorSkyblue_100 px-6 py-2 rounded-3xl"
              )}
            >
              <Image
                style={tailwind("w-[21px] h-[21px]")}
                contentFit="cover"
                source={require("../assets/Reports-Active.png")}
              />
              <Text
                style={tailwind(
                  "text-colorDarkslategray_200 text-center text-sm font-semibold ml-2  text-colorWhite"
                )}
              >
                Reports
              </Text>
            </View>
          ) : (
            <Image
              style={tailwind("w-[21px] h-[21px]")}
              contentFit="cover"
              source={require("../assets/Reports-InActive.svg")}
            />
          )}
        </View>
      </Pressable>

      {/* Ottobots */}
      <Pressable
        style={[tailwind("w-fit")]}
        onPress={() => {
          handleNavigation("ottobots");
        }}
      >
        <View style={tailwind("flex flex-row justify-center items-center")}>
          {activeTab === "Ottobots" ? (
            <View
              style={tailwind(
                "flex flex-row justify-center items-center bg-colorSkyblue_100 px-6 py-2 rounded-3xl"
              )}
            >
              <Image
                style={tailwind("w-[21px] h-[18px]")}
                contentFit="cover"
                source={require("../assets/Ottobot-Active.png")}
              />
              <Text
                style={tailwind(
                  "text-colorDarkslategray_200 text-center text-sm font-semibold ml-2  text-colorWhite"
                )}
              >
                Ottobots
              </Text>
            </View>
          ) : (
            <Image
              style={tailwind("w-[21px] h-[18px]")}
              contentFit="cover"
              source={require("../assets/Ottobot-InActive.svg")}
            />
          )}
        </View>
      </Pressable>
    </View>
  );
};

export default BottomNavigation;
