import { useMemo } from "react";
import { Dimensions, Text, View } from "react-native";
import { LineChart } from "react-native-chart-kit";
import { useTailwind } from "tailwind-rn";

const screenWidth = Dimensions.get("window").width;
const TotalSalesChart = (props) => {

    const tailwind = useTailwind();

  const chartConfig = {
 
    backgroundColor: "#ffffff", // Solid background color
    backgroundGradientFrom: "#ffffff", // Start of gradient (same color for solid)
    backgroundGradientTo: "#ffffff", // End of gradient (same color for solid)
    decimalPlaces: 2, // number of decimal places to show
    color: (opacity = 1) => `rgba(0,0,0 , ${opacity})`,
    labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`, // X and Y axis label color
    backgroundGradientFromOpacity: 0, // Remove gradient background
    backgroundGradientToOpacity: 0,   // Remove gradient background
    fillShadowGradient: 'transparent',
    fillShadowGradientFrom: 'transparent',
    fillShadowGradientTo: 'transparent',
    fillShadowGradientOpacity: 0, // Ensure no fill color
    strokeWidth: 3, // optional, default 3
    barPercentage: 5.5,
    propsForDots: {
      r: '3',
      strokeWidth: '2',
      stroke: '#0042fd', // Points border color
      fill: '#0042fd',   // Points fill color
    },
  };

  const labels = props?.analyticsData?.totalSalesVsDate?.sort((a, b) => new Date(a.date.split('-').reverse().join('-')) - new Date(b.date.split('-').reverse().join('-')))?.map((order) => order?.date)

  const data = {
    labels:labels.map((label, idx) =>
      {

        const labelRadix = Math.max(Math.floor(labels.length / 5), 1)
        return idx % labelRadix === 0 ? label : ''
      },
  ),
    datasets: [
      {
        label: "Total Sales",
        data: props?.analyticsData?.totalSalesVsDate?.sort((a, b) => new Date(a.date.split('-').reverse().join('-')) - new Date(b.date.split('-').reverse().join('-')))?.map((order) => order?.sales),
            color: (opacity = 1) => `rgba(0,66,253 , ${opacity})`,
        // borderColor: "rgb(0, 0, 255)",
        // backgroundColor: "rgba(0, 0, 255, 1)",
      },
    ],
  };


  const chartWidth = useMemo(() => {
    if(Dimensions.get('window').width>=870){
      return ((Dimensions.get("window").width - 150)/3)-50;
    }
    else if(Dimensions.get('window').width<870 && Dimensions.get('window').width>=768){
      return Dimensions.get("window").width - 73;
    }

    return Dimensions.get("window").width - 45;
  }, [Dimensions.get("window").width]);

  return (
    <View style={[tailwind("bg-white p-2 text-left text-base font-bold w-full rounded-[7px] pt-4 w-full")]}>
      <Text style={tailwind("mb-2.5 font-poppins-bold text-base")}>
        {" "}
        Total sales -{" "}
        <Text style={tailwind("text-colorSkyblue_100 font-poppins-bold")}>
        {props?.currentCurrency}
        {props?.analyticsData?.totalSales?.toFixed(2)}
        </Text>
      </Text>
      <View style={tailwind("flex")}>
        <LineChart data={data} width={chartWidth} xLabelsOffset={-10} height={340} verticalLabelRotation={60}  chartConfig={chartConfig}    style={{
            marginRight: -28,
            marginLeft: -10,
          }}/>
      </View>
    </View>
  );
};


export default TotalSalesChart;
