import { createSlice } from "@reduxjs/toolkit"

const AppHeaderSlice = createSlice({
  name: "appHeader",
  initialState: {
    isStoreActive: true,
    showSidebarMenu: false
  },
  reducers: {
    setIsStoreActive: (state, action) => {
      state.isStoreActive = action.payload
    },
    setShowSidebarMenu: (state, action) => {
      state.showSidebarMenu = action.payload
    }
  }
})

export const {setIsStoreActive, setShowSidebarMenu} = AppHeaderSlice.actions;
export default AppHeaderSlice.reducer;