import React, { useEffect, useMemo } from "react";
import { Image } from "expo-image";
import {
  StyleSheet as StyleSheet2,
  Text,
  View,
  Pressable,
  ScrollView,
} from "react-native";
import { useTailwind } from "tailwind-rn";
import { useLinkTo, useRoute } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  AccordionList,
  Collapse,
  CollapseHeader,
  CollapseBody,
} from "accordion-collapse-react-native";
import { acceptOrder, getorderlist } from "../api";
import StyleSheet from "react-native-media-query";
import { Platform } from "react-native";
import { ActivityIndicator, Modal, Portal } from "react-native-paper";

import CancelPopup from "./CancelPopup";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationPopup from "./ConfirmationPopup";
import Toast from "react-native-toast-message";
import Layout from "./Layout";

import { thermalPrint } from "./printerService";

const OrderDashboard = ({ setShowLoader, showLoader }) => {
  const tailwind = useTailwind();
  const [displayOrder, setDisplayOrder] = React.useState([true, false]);
  const ordersData =  useSelector((state) => state?.orders?.ordersData);
  const currentOrdersData =  useSelector((state) => state?.orders?.currentOrdersData);
  const pastOrdersData =  useSelector((state) => state?.orders?.pastOrdersData);
  const [selectedOrder, setSelectedOrder] = React.useState({
    index: 0,
    type: "current",
  });
  const storeListData = useSelector((state) => state?.store?.storeListData);
  const store = useSelector((state) => state?.store?.selectedStore.storeId);
  // const [newOrders, setNewOrders] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const route = useRoute();

  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const showToast = ({ type, text }) => {
    Toast.show({
      type: type,
      text1: text,
    });
  };

  const cancelOrderChild = (
    <CancelPopup
      orderId={
        selectedOrder.type === "current"
          ? currentOrdersData[selectedOrder.index]?.orderId
          : pastOrdersData[selectedOrder.index]?.orderId
      }
      items={
        selectedOrder.type === "current"
          ? currentOrdersData[selectedOrder.index]?.orderData
          : pastOrdersData[selectedOrder.index]?.orderData
      }
      hideModal={hideModal}
    />
  );

  const acceptOrderSubmit = () => {
    AsyncStorage.getItem("token").then((token) => {
      setShowLoader(true);
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        acceptOrder(
          selectedOrder.type === "current"
            ? currentOrdersData[selectedOrder.index]?.orderId
            : pastOrdersData[selectedOrder.index]?.orderId,
          token
        )
          .then((res) => {
            let type = "";
            if (res?.statusCode === 200) {
              type = "success";
            } else {
              type = "error";
            }

            showToast({ type: type, text: res?.data?.message });
            setShowLoader(false);
          })
          .catch((e) => {
            showToast({ type: "error", text: "Error Order not Accepted" });
            setShowLoader(false);
          });
      }
    });
    hideModal();
  };

  const acceptOrderChild = (
    <ConfirmationPopup
      title={"Accept Order"}
      onSubmit={acceptOrderSubmit}
      hideModal={hideModal}
    />
  );
  const [modalChild, setModalChild] = React.useState(acceptOrderChild);

  const openIcon = require("../assets/-icon-down1.png");
  const closeIcon = require("../assets/-icon-down.png");

  const list = [
    {
      id: 1,
      title: "Current Orders",
      body: [
        {
          orderId: "557834",
          store: "PUBGRUB",
          orderStatus: "Order Delivered",
          deliveryLocation: "ASPN",
        },
        {
          orderId: "557834",
          store: "PUBGRUB",
          orderStatus: "Order Delivered",
          deliveryLocation: "ASPN",
        },
        {
          orderId: "557834",
          store: "PUBGRUB",
          orderStatus: "Order Delivered",
          deliveryLocation: "ASPN",
        },
        {
          orderId: "557834",
          store: "PUBGRUB",
          orderStatus: "Order Delivered",
          deliveryLocation: "ASPN",
        },
        {
          orderId: "557834",
          store: "PUBGRUB",
          orderStatus: "Order Delivered",
          deliveryLocation: "ASPN",
        },
        {
          orderId: "557834",
          store: "PUBGRUB",
          orderStatus: "Order Delivered",
          deliveryLocation: "ASPN",
        },
      ],
    },
    {
      id: 2,
      title: "Past Orders",
      body: [
        {
          orderId: "557834",
          store: "PUBGRUB",
          orderStatus: "Order Delivered",
          deliveryLocation: "ASPN",
        },
        {
          orderId: "557834",
          store: "PUBGRUB",
          orderStatus: "Order Delivered",
          deliveryLocation: "ASPN",
        },
        {
          orderId: "557834",
          store: "PUBGRUB",
          orderStatus: "Order Delivered",
          deliveryLocation: "ASPN",
        },
        {
          orderId: "557834",
          store: "PUBGRUB",
          orderStatus: "Order Delivered",
          deliveryLocation: "ASPN",
        },
        {
          orderId: "557834",
          store: "PUBGRUB",
          orderStatus: "Order Delivered",
          deliveryLocation: "ASPN",
        },
        {
          orderId: "557834",
          store: "PUBGRUB",
          orderStatus: "Order Delivered",
          deliveryLocation: "ASPN",
        },
      ],
    },
  ];

  const mediaQuery = StyleSheet.create({
    ordersDetails: {
      "@media (max-width: 768px)": {
        // maxHeight: 881,
        // height: "60%",
      },
    },
    ordersList: {
      "@media (max-width: 768px)": {
        maxHeight: 624,
      },
    },
    ordersListItem: {
      "@media (max-width: 768px)": {
        maxHeight: 544,
      },
    },
    ordersListItemBody: {
      "@media (max-width: 768px)": {
        maxHeight: "90%",
      },
    },
  });

  const containerStyle = {
    backgroundColor: "white",
    zIndex: 100,
    borderRadius: 10,
  };

  const modalStyles = {
    zIndex: 100,
    width: Platform.OS === "web" ? "500px" : "10px",
    marginHorizontal: "auto",
  };

  if (Platform.OS !== "web") {
    modalStyles.marginHorizontal = 10;
  }

  return (
    <Layout setShowLoader={setShowLoader} showLoader={showLoader}>
      
      <View style={[tailwind("flex-row h-full w-full relative pb-[60px] md:pb-[0px]")]}>
        {/* Main Content */}
        <Portal>
          <Modal
            visible={visible}
            onDismiss={hideModal}
            contentContainerStyle={containerStyle}
            style={modalStyles}
          >
            {modalChild}
          </Modal>
        </Portal>
        {/* Content Container */}
        <View
          style={[
            tailwind(
              "w-full z-0 flex-[2_0_70%] md:flex-[2_0_88%] flex flex-col md:flex-row md:overflow-y-hidden"
            ),
          ]}
        >
          {/* <ScrollView scrollEnabled={!(Platform.OS==="web")?true:false}> */}
          <ScrollView
            style={tailwind("")}
            contentContainerStyle={tailwind(
              `flex flex-col md:flex-row md:h-full`
            )}
            scrollEnabled={Platform.OS === "web" ? true : true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            {/* Orders List */}
            <View
              style={[
                tailwind(
                  `${
                    (displayOrder[0] || displayOrder[1]) &&
                    ordersData &&
                    ordersData?.length > 0
                      ? "h-[624px]"
                      : "h-[132px]"
                  } max-h-[624px] md:max-h-full min-w-[215px] p-2 mb-4 md:mb-0 md:mr-4 md:h-full bg-colorWhite rounded-[10px] md:flex-[2_0_20%] lg:flex-[2_0_24%]`
                ),
                styles.transit,
                styles.transitShadowBox,
              ]}
            >
              {/* <AccordionList
                  list={list}
                  header={head}
                  body={body}
                  keyExtractor={item => `${item.id}`}
                  expandedIndex = {0}
                /> */}
              <View style={tailwind("h-full")}>
                {list.map((item, index) => {
                  let margin = index === 1 ? "mt-2" : "";
                  let viewHeight = "";
                  let maxViewHeight =
                    56 +
                    (index === 0
                      ? currentOrdersData.length
                      : pastOrdersData.length) *
                      82;

                  return (
                    <View
                      key={item?.title + "" + index}
                      style={[
                        tailwind(
                          `${
                            displayOrder[index] &&
                            ordersData &&
                            ordersData?.length > 0
                              ? `${
                                  maxViewHeight > 544
                                    ? "h-[544px]"
                                    : `h-[${maxViewHeight}px]`
                                }`
                              : "h-[56px]"
                          } max-h-[544px] md:h-fit md:max-h-[calc(98%-56px)] ${margin}`
                        ),
                        mediaQuery.styles.ordersListItem,
                      ]}
                    >
                      <Collapse
                        onToggle={(isExpanded) => {
                          if (isExpanded) {
                            setDisplayOrder((prev) => {
                              if (index == 0) {
                                return [isExpanded, false];
                              }
                              return [false, isExpanded];
                            });
                          } else {
                            setDisplayOrder((prev) => {
                              if (index == 0) {
                                return [false, displayOrder[1]];
                              }
                              return [displayOrder[0], false];
                            });
                          }
                        }}
                        isExpanded={displayOrder[index]}
                        style={tailwind("h-full")}
                      >
                        <CollapseHeader>
                          {/* <View style={tailwind(`h-[${maxViewHeight}px] hidden`)}></View> */}

                          <View
                            style={[
                              tailwind(
                                "w-full items-center py-3 px-2.5 mb-2 justify-between flex-row py-3 rounded-lg bg-colorSkyblue_100"
                              ),
                            ]}
                          >
                            <Text
                              style={[
                                tailwind(
                                  "font-bold font-poppins-bold text-left flex-1 text-base text-colorWhite"
                                ),
                              ]}
                            >
                              {" "}
                              {item?.title}
                            </Text>
                            <Image
                              style={tailwind("w-[12px] h-[6px]")}
                              contentFit="cover"
                              source={displayOrder[index] ? openIcon : closeIcon}
                            />
                          </View>
                        </CollapseHeader>
                        <CollapseBody
                          style={[
                            tailwind(" h-[calc(100%-56px)] overflow-scroll"),
                            mediaQuery.styles.ordersListItemBody,
                          ]}
                        >
                          {/* <View
                          style={[
                            tailwind("w-full flex flex-col")
                          ]}
                        > */}
                          <ScrollView
                            scrollEnabled={true}
                            contentContainerStyle={tailwind(
                              "w-full flex flex-col flex-grow justify-center"
                            )}
                            showsHorizontalScrollIndicator={false}
                            showsVerticalScrollIndicator={false}
                          >
                            <View>
                              {
                                (index === 0
                                  ? currentOrdersData
                                  : pastOrdersData
                                )?.map((ele, index2) => (
                                  <Pressable
                                    key={ele?.orderId + "" + index}
                                    onPress={() =>
                                      setSelectedOrder(() => {
                                        return {
                                          index: index2,
                                          type: index === 0 ? "current" : "past",
                                        };
                                      })
                                    }
                                  >
                                    <View
                                      style={[
                                        tailwind(
                                          `w-full my-2 py-3 justify-between items-center flex-row px-4 overflow-hidden rounded-lg bg-colorSkyblue_200 ${(((index===0 && selectedOrder?.type === "current") || (index===1 && selectedOrder?.type === "past")) && index2===selectedOrder?.index)?'border border-1 border-colorSkyblue_100':''}`
                                        ),]}
                                    >
                                      <View style={tailwind("")}>
                                        <Text
                                          style={[
                                            tailwind(
                                              "font-bold font-poppins-bold text-base self-stretch text-left text-colorBlack"
                                            ),
                                          ]}
                                        >
                                          {ele?.orderId}
                                        </Text>
                                        <Text
                                          style={[
                                            tailwind(
                                              "text-[12px] text-left font-[500] self-stretch text-colorGray_300 font-poppins-medium"
                                            ),
                                          ]}
                                        >
                                          {/* {ele?.store} */}
                                          {ele?.storeId}
                                        </Text>
                                      </View>
                                      <View
                                        style={[
                                          tailwind(
                                            "h-[42px] items-end justify-center flex-1 max-w-[60%]"
                                          ),
                                        ]}
                                      >
                                        <View
                                          style={[
                                            tailwind(
                                              `h-[24px] overflow-hidden px-2.5 py-1.5 rounded-lg items-center flex-row ${
                                                ele?.status === "order delivered"
                                                  ? "bg-colorLimegreen_300":
                                                  ((ele?.status ==="order confirmed")?"bg-[#F0A202]"
                                                  : "bg-colorTomato_200")
                                              }`
                                            ),
                                          ]}
                                        >
                                          <Text
                                            style={[
                                              tailwind(
                                                "capitalize text-[8px] font-bold font-poppins-bold text-left text-colorWhitesmoke_100"
                                              ),
                                            ]}
                                          >
                                            {/* {ele?.orderStatus} */}
                                            {ele?.status}
                                          </Text>
                                        </View>
                                        <Text
                                          style={[
                                            tailwind(
                                              "text-colorGray_400 font-[500] mt-[2px] text-right text-xs self-stretch font-poppins-medium"
                                            ),
                                          ]}
                                        >
                                          {/* {ele?.deliveryLocation} */}
                                          {ele?.drop?.locationName?.["en-US"]}
                                        </Text>
                                      </View>
                                    </View>
                                  </Pressable>
                                ))
                              }
                            </View>
                          </ScrollView>
                          {/* </View> */}
                        </CollapseBody>
                      </Collapse>
                    </View>
                  );
                })}
              </View>
            </View>

            {/* new order details */}
            <View
              style={[
                tailwind(
                  `md:block p-2 ${
                    Platform.OS === "web" ? "max-h-[881px]" : "pb-4"
                  } md:max-h-full md:h-full bg-colorWhite rounded-[10px] md:flex-[6_0_60%] lg:flex-[6_0_64%] overflow-x-hidden`
                ),
                styles.transitShadowBox,
                mediaQuery.styles.ordersDetails,
              ]}
            >
              <View
                style={tailwind(
                  `w-full flex flex-col justify-between ${
                    Platform.OS === "web" ? "h-full" : ""
                  }`
                )}
              >
                {/* Order Details header*/}
                <View style={tailwind("flex items-center w-full min-h-[108px]")}>
                  <View
                    style={[
                      tailwind(
                        "justify-between flex flex-row py-3 w-full px-2 sm:px-4 items-center rounded-t-lg max-h-[102px]"
                      ),
                      { backgroundColor: "#0dbad5" },
                    ]}
                  >
                    <View
                      style={[
                        tailwind(
                          "h-full flex-grow mr-2 sm:mr-0 text-left text-colorWhite flex flex-col sm:flex-row shrink sm:shrink-0"
                        ),
                      ]}
                    >
                      <View
                        style={tailwind(
                          "flex flex-col md:flex-row items-baseline md:items-center"
                        )}
                      >
                        <View style={tailwind("flex flex-row items-baseline")}>
                          <Text
                            style={[
                              tailwind(
                                "text-lg font-bold font-poppins-bold text-colorWhite"
                              ),
                            ]}
                          >
                            #
                            {selectedOrder.type === "current"
                              ? currentOrdersData[selectedOrder.index]?.orderId
                              : pastOrdersData[selectedOrder.index]?.orderId}
                          </Text>

                          <Text
                            style={[
                              tailwind(
                                "text-[8px] font-bold font-poppins-bold text-colorWhite"
                              ),
                            ]}
                          >
                            {" "}
                            ,{" "}
                          </Text>
                        </View>
                        <Text
                          style={[
                            tailwind("text-xs font-poppins-bold text-colorWhite"),
                          ]}
                        >
                          {selectedOrder.type === "current"
                            ? currentOrdersData[selectedOrder.index]?.storeId
                            : pastOrdersData[selectedOrder.index]?.storeId}
                        </Text>
                      </View>
                      <Text
                        style={[
                          tailwind(
                            "font-semibold text-left flex items-center text-xs text-colorWhite mt-2 sm:mt-0 sm:mx-auto min-w-[120px] font-poppins-semiBold"
                          ),
                        ]}
                      >
                        {new Date(
                          selectedOrder.type === "current"
                            ? currentOrdersData[selectedOrder.index]?.createdAt
                            : pastOrdersData[selectedOrder.index]?.createdAt
                        )
                          .toLocaleString("en-GB", {
                            // weekday: "short",
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                            timeZoneName: "short",
                            timeZone: storeListData?.[0]?.timezone,
                          })
                          ?.replace(",", " ")}{" "}
                        ,{" "}
                        {new Date(
                          selectedOrder.type === "current"
                            ? currentOrdersData[selectedOrder.index]?.createdAt
                            : pastOrdersData[selectedOrder.index]?.createdAt
                        ).toLocaleTimeString("en-US", {
                          timeZone: storeListData?.[0]?.timezone,
                        })}
                      </Text>
                    </View>

                    <View
                      style={[
                        tailwind(
                          `min-w-[123px] py-1.5 rounded-lg items-center h-[44px] flex-row px-2.5 justify-end ${
                            (selectedOrder.type === "current"
                              ? currentOrdersData[selectedOrder.index]?.status
                              : pastOrdersData[selectedOrder.index]?.status) ===
                               "order delivered"
                                  ? "bg-colorLimegreen_300":
                                  ((selectedOrder.type === "current"
                                    ? currentOrdersData[selectedOrder.index]?.status
                                    : pastOrdersData[selectedOrder.index]?.status)==="order confirmed"?"bg-[#F0A202]"
                                  : "bg-colorTomato_200")
                          }`
                        ),
                      ]}
                    >
                      <Text
                        style={[
                          tailwind(
                            "font-bold text-left  font-poppins-bold text-xs text-colorWhitesmoke_100 capitalize"
                          ),
                        ]}
                      >
                        {selectedOrder.type === "current"
                          ? currentOrdersData[selectedOrder.index]?.status
                          : pastOrdersData[selectedOrder.index]?.status}
                      </Text>
                    </View>

                    {/* <Pressable
                      style={tailwind(
                        "w-fit h-fit rounded-lg px-4 py-3 bg-black ml-4"
                      )}
                      onPress={refreshOrders}
                    >
                      <Text style={tailwind("text-white font-poppins-regular")}>
                        Refresh
                      </Text>
                    </Pressable> */}
                  </View>

                  <View
                    style={[
                      tailwind(
                        "max-h-[85px] items-center rounded-b-lg pl-3 sm:pl-5 py-2 pr-4 sm:pr-8 justify-between w-full flex-row items-start"
                      ),
                      { backgroundColor: "#e5f7fa" },
                    ]}
                  >
                    <View
                      style={tailwind("flex flex-col shrink min-w-[42px] mr-1")}
                    >
                      <Text
                        style={tailwind(
                          "text-colorDarkslategray_100 leading-5 text-sm text-left font-bold font-poppins-bold"
                        )}
                      >
                        Name
                      </Text>
                      <Text
                        style={[
                          tailwind(
                            "text-colorGray_100 opacity-95 leading-[18px] text-xs font-poppins-regular text-left"
                          ),
                        ]}
                      >
                        {selectedOrder.type === "current"
                          ? currentOrdersData[selectedOrder.index]?.userName
                          : pastOrdersData[selectedOrder.index]?.userName}
                      </Text>
                    </View>
                    <View style={tailwind("flex flex-col shrink")}>
                      <Text
                        style={tailwind(
                          "text-colorDarkslategray_100 leading-5 text-sm text-left font-bold font-poppins-bold"
                        )}
                      >
                        Contact Number
                      </Text>
                      <Text
                        style={[
                          tailwind(
                            "text-colorGray_100 opacity-95 leading-[18px] text-xs font-poppins-regular text-left"
                          ),
                        ]}
                      >
                        {selectedOrder.type === "current"
                          ? currentOrdersData[selectedOrder.index]?.mobileNumber
                          : pastOrdersData[selectedOrder.index]?.mobileNumber}
                      </Text>
                    </View>
                    <View style={tailwind("flex flex-col shrink")}>
                      <Text
                        style={tailwind(
                          "text-colorDarkslategray_100 leading-5 text-sm text-left font-bold font-poppins-bold"
                        )}
                      >
                        Delivery Location
                      </Text>
                      <Text
                        style={[
                          tailwind(
                            "text-colorGray_100 opacity-95 leading-[18px] text-xs font-poppins-regular text-left"
                          ),
                        ]}
                      >
                        {selectedOrder.type === "current"
                          ? currentOrdersData[selectedOrder.index]?.drop
                              ?.locationName?.["en-US"]
                          : pastOrdersData[selectedOrder.index]?.drop
                              ?.locationName?.["en-US"]}
                      </Text>
                    </View>

                    <Pressable
                      style={tailwind("flex flex-col shrink h-[21px] my-auto")}
                      onPress={() => {
                        if(Platform.OS==='web'){
                          thermalPrint(
                            selectedOrder.type === "current"
                              ? currentOrdersData[selectedOrder.index]
                              : pastOrdersData[selectedOrder.index],
                            storeListData,
                            storeListData[0]?.timezone
                          )
                        }

                      }} 
                    >
                      <Image
                        style={tailwind("w-[21px] h-[21px]")}
                        contentFit="cover"
                        source={require("../assets/frame-688.png")}
                      />
                    </Pressable>
                  </View>
                </View>

                {/* Order Items */}
                <View
                  style={tailwind(
                    "w-full flex pl-3 sm:pl-5 pr-2 sm:pr-4 my-2 h-[calc(96%-315px)] max-h-full min-h-[160px] justify-start"
                  )}
                >
                  <ScrollView
                    contentContainerStyle={tailwind("w-full pr-4")}
                    showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
                  >
                    <View style={tailwind("items-center w-full bg-colorWhite")}>
                      {(selectedOrder.type === "current"
                        ? currentOrdersData[selectedOrder.index]?.orderData
                        : pastOrdersData[selectedOrder.index]?.orderData
                      )?.map((item, index) => {
                        return (
                          <View
                            key={index}
                            style={tailwind(
                              "px-0 justify-between self-stretch items-center flex-row border-solid py-1 border-b border-colorLightgray"
                            )}
                          >
                            <View style={tailwind("w-[50%]")}>
                              <View
                                style={tailwind(
                                  "w-full justify-between items-center flex-row"
                                )}
                              >
                                <Text
                                  style={[
                                    tailwind(
                                      "text-colorBlack flex text-left items-center font-medium font-poppins-medium leading-6 text-base flex-1"
                                    ),
                                  ]}
                                >
                                  {item?.name}
                                </Text>
                              </View>
                              <Text
                                style={[
                                  tailwind(
                                    "text-colorBlack opacity-35 leading-[18px] text-xs font-poppins-regular text-left"
                                  ),
                                ]}
                              >
                                500ml Plastic Bottle
                              </Text>
                            </View>
                            <Text
                                  style={[
                                    tailwind(
                                      "text-colorBlack ml-2 w-[20px] font-medium font-poppins-medium leading-6 text-base h-[27px] flex text-left items-center"
                                    ),
                                  ]}
                                >
                                  x{item?.quantity}
                                </Text>
                            <Text
                              style={[
                                tailwind(
                                  "leading-[14px] text-sm min-w-[36px] text-left font-bold font-poppins-bold text-colorBlack"
                                ),
                              ]}
                            >
                              ${item?.price}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                  </ScrollView>
                </View>

                {/* Subtotal */}
                <View
                  style={tailwind(
                    "border-colorDarkgray bg-colorWhite border-2 border-solid rounded-lg py-3.5 px-3 sm:px-5 h-[130px] flex justify-center"
                  )}
                >
                  <View
                    style={tailwind(
                      "justify-between flex w-full items-center flex-row"
                    )}
                  >
                    <Text
                      style={[
                        tailwind(
                          "text-colorBlack font-semibold font-poppins-semiBold leading-4 text-left text-base"
                        ),
                      ]}
                    >
                      Subtotal
                    </Text>
                    <Text
                      style={[
                        tailwind(
                          "leading-[14px] text-sm text-right font-bold font-poppins-bold text-colorBlack"
                        ),
                      ]}
                    >
                      ${selectedOrder.type === "current"
                        ? parseFloat(
                            currentOrdersData[selectedOrder.index]?.totalAmount
                          ).toFixed(2)
                        : parseFloat(
                            pastOrdersData[selectedOrder.index]?.totalAmount
                          ).toFixed(2)}
                    </Text>
                  </View>
                  <View
                    style={[
                      tailwind(
                        "flex w-full flex-row justify-between items-center"
                      ),
                    ]}
                  >
                    <Text
                      style={[
                        tailwind(
                          "text-colorBlack font-semibold font-poppins-semiBold leading-4 text-left text-base"
                        ),
                      ]}
                    >
                      Additional Charges *
                    </Text>
                    <Text
                      style={[
                        tailwind(
                          "leading-[14px] text-sm text-right font-bold font-poppins-bold text-colorBlack"
                        ),
                      ]}
                    >
                      ${selectedOrder.type === "current"
                        ? parseFloat(
                            currentOrdersData[selectedOrder.index]?.tax
                          ).toFixed(2)
                        : parseFloat(
                            pastOrdersData[selectedOrder.index]?.tax
                          ).toFixed(2)}
                    </Text>
                  </View>
                  <View
                    style={[
                      tailwind(
                        "flex w-full flex-row justify-between items-center"
                      ),
                    ]}
                  >
                    <Text
                      style={[
                        tailwind(
                          "text-colorTomato_200 leading-4 text-left text-base font-semibold font-poppins-semiBold"
                        ),
                      ]}
                    >
                      Discount
                    </Text>
                    <Text
                      style={[
                        tailwind(
                          "leading-[14px] text-sm text-right font-bold font-poppins-bold text-colorTomato_200"
                        ),
                      ]}
                    >
                      -$
                      {selectedOrder.type === "current"
                        ? (
                            parseFloat(
                              currentOrdersData[selectedOrder.index]?.totalAmount
                            ) -
                            parseFloat(
                              currentOrdersData[selectedOrder.index]?.cartTotal
                            )
                          ).toFixed(2)
                        : (
                            parseFloat(
                              pastOrdersData[selectedOrder.index]?.totalAmount
                            ) -
                            parseFloat(
                              pastOrdersData[selectedOrder.index]?.cartTotal
                            )
                          ).toFixed(2)}
                    </Text>
                  </View>
                  <View
                    style={[
                      tailwind(
                        "flex w-full flex-row justify-between items-center"
                      ),
                    ]}
                  >
                    <Text
                      style={[
                        tailwind(
                          "font-bold font-poppins-bold text-2xl text-left text-colorSkyblue_100"
                        ),
                      ]}
                    >
                      Total
                    </Text>
                    <Text
                      style={[
                        tailwind(
                          "font-bold font-poppins-bold text-2xl text-right text-colorSkyblue_100"
                        ),
                      ]}
                    >
                      ${selectedOrder.type === "current"
                        ? parseFloat(
                            currentOrdersData[selectedOrder.index]?.cartTotal
                          ).toFixed(2)
                        : parseFloat(
                            pastOrdersData[selectedOrder.index]?.cartTotal
                          ).toFixed(2)}
                    </Text>
                  </View>
                </View>

                {/* Buttons */}
                {(selectedOrder.type === "current"
                  ? currentOrdersData[selectedOrder.index]?.statusCode <= 8
                  : pastOrdersData[selectedOrder.index]?.statusCode <= 8) && (
                  <View
                    style={tailwind(
                      "w-full mt-2 justify-center items-center flex-row py-2"
                    )}
                  >
                    <Pressable
                      style={[
                        tailwind(
                          "bg-colorTomato_100 w-[45%] h-full px-6 sm:px-8 py-4 rounded-lg justify-center items-center flex-row flex-1"
                        ),
                      ]}
                      onPress={() => {
                        showModal();
                        setModalChild(cancelOrderChild);
                      }}
                    >
                      <Image
                        style={tailwind("w-[22px] h-[22px]")}
                        contentFit="cover"
                        source={require("../assets/-icon-close.png")}
                      />
                      <Text
                        style={[
                          tailwind(
                            "text-colorWhite font-poppins-bold ml-2.5 font-bold text-center text-[15px] sm:text-base flex justify-center items-center "
                          ),
                        ]}
                      >
                        Decline Order
                      </Text>
                    </Pressable>
                    <Pressable
                      style={[
                        tailwind(
                          "bg-colorLimegreen_100  ml-4 w-[45%] h-full px-6 sm:px-8 py-4 rounded-lg justify-center items-center flex-row flex-1"
                        ),
                      ]}
                      onPress={() => {
                        showModal();
                        setModalChild(acceptOrderChild);
                      }}
                    >
                      <Image
                        style={tailwind("w-[22px] h-[22px]")}
                        contentFit="cover"
                        source={require("../assets/-icon-check.png")}
                      />
                      <Text
                        style={[
                          tailwind(
                            "text-colorWhite font-poppins-bold ml-2.5 font-bold text-center text-[15px] sm:text-base flex justify-center items-center "
                          ),
                        ]}
                      >
                        Accept Order
                      </Text>
                    </Pressable>
                  </View>
                )}
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </Layout>
  );
};

const styles = StyleSheet2.create({
  shadow: {
    shadowColor: "#000", // Shadow color
    shadowOffset: { width: 0, height: 0 }, // Offset for shadow
    shadowOpacity: 0.12, // Opacity of the shadow
    shadowRadius: 13, // Radius of the shadow blur
  },
  transitShadowBox: {
    shadowOpacity: 1,
    shadowOffset: {
      width: 0,
      height: 39,
    },
    shadowColor: "rgba(0, 0, 0, 0)",
  },

  sidebar: {
    shadowRadius: 1,
    // elevation: 1,
    shadowOpacity: 1,
    shadowOffset: {
      width: 0,
      height: 39,
    },
    shadowColor: "rgba(0, 0, 0, 0)",
  },

  transit: {
    shadowRadius: 14,
    // elevation: 14,
  },
});

export default OrderDashboard;
