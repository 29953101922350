import React, { useEffect } from "react";
import { Pressable, Text, View, TextInput } from "react-native";
import { Icon, RadioButton, Checkbox } from "react-native-paper";
import { useTailwind } from "tailwind-rn";
import { cancelOrder, getorderlist } from "../api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../utils/redux/slice/UserSlice";
import { Platform } from "react-native";
import { useLinkTo } from "@react-navigation/native";
import { Image } from "expo-image";

const CancelPopup = ({ orderId, items, hideModal }) => {
  const [checked, setChecked] = React.useState("itemUnavailable");
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [otherReason, setOtherReason] = React.useState("");
  const dispatch = useDispatch();
  const tailwind = useTailwind();
  const linkTo = useLinkTo();

  const submit = () => {
    if(checked === "itemUnavailable" && selectedItems.length === 0){
      return;
    }
    if(checked === "other" && otherReason === ""){
      return;
    }
    
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        cancelOrder(orderId, otherReason, checked, selectedItems, token).then(
          (res) => {
              AsyncStorage.getItem("token").then((token) => {
                if (
                  !(token === null || token === "null" || token === undefined || !token)
                ) {
                  getorderlist(10, 1, token, dispatch);
                }
              });
          }
        );
      }
    });
    hideModal();
  };

  return (
    <View style={tailwind("font-poppins-regular mb-[20px]")}>
      <View
        style={tailwind(
          "flex flex-row justify-between bg-colorSkyblue_100 p-5 items-center rounded-t-lg"
        )}
      >
        <Text
          style={tailwind("text-2xl text-white font-bold font-poppins-bold")}
        >
          Cancel Order
        </Text>
        <Pressable onPress={() => hideModal()}>
          {/* <Icon source="close" size={28} color="white" /> */}
          <Image
                style={tailwind("w-[24px] h-[24px]")}
                contentFit="cover"
                source={require("../assets/-icon-close.png")}
              />
        </Pressable>
      </View>
      <View style={tailwind("p-4")}>
        <View style={tailwind("flex flex-row items-center mb-2 w-[calc(100%-32px)]")}>
          <RadioButton.Android
            value="itemUnavailable"
            status={checked === "itemUnavailable" ? "checked" : "unchecked"}
            onPress={() => setChecked("itemUnavailable")}
            color="blue"
          />
          <Text style={tailwind("font-poppins-regular")}>Item Unavailable</Text>
        </View>
        {checked === "itemUnavailable" && (
          <View style={tailwind("ml-4  w-[calc(100%-80px)]")}>
            {items?.map((item) => {
              return (
                <View
                  style={tailwind(
                    "flex flex-row items-center mb-2 font-poppins-regular w-full relative"
                  )}
                >
                  {/* <View style={tailwind(`${(Platform.OS === "web" )? "" : "border border-black absolute w-[24px] h-[24px] left-[6px]"}`)}></View> */}
                    <Checkbox.Android
                      status={
                        selectedItems.filter((i) => i === item.name).length > 0?"checked":"unchecked"
                      }
                      
                      color="blue"
                      // uncheckedColor="blue"
                      onPress={() =>
                        setSelectedItems((prev) => {
                          return prev.includes(item.name)
                            ? prev.filter((i) => i !== item.name)
                            : [...prev, item.name];
                        })
                      }
                    />
                  <Text style={tailwind("ml-2 font-poppins-regular flex-shrink")}>
                    {item.name}
                  </Text>
                </View>
              );
            })}
          </View>
        )}

        <View
          style={tailwind(
            "flex flex-row items-center mb-2 font-poppins-regular"
          )}
        >
          <RadioButton.Android
            value="unableToService"
            status={checked === "unableToService" ? "checked" : "unchecked"}
            onPress={() => setChecked("unableToService")}
            color="blue"
          />
          <Text style={tailwind("font-poppins-regular")}>
            Unable to service
          </Text>
        </View>
        <View
          style={tailwind(
            "flex flex-row items-center mb-2 font-poppins-regular"
          )}
        >
          <RadioButton.Android
            value="other"
            status={checked === "other" ? "checked" : "unchecked"}
            onPress={() => setChecked("other")}
            color="blue"
          />
          <Text style={tailwind("font-poppins-regular")}>Other</Text>
        </View>

        {checked === "other" && (
          <View style={tailwind("ml-4  w-[calc(100%-16px)]")}>
            <TextInput
              style={tailwind(
                "w-[calc(100%-16px)] h-10 border border-gray-300 rounded-lg px-2 ml-4"
              )}
              onChange={(e) => setOtherReason(e.target.value)}
              value={otherReason}
            />
          </View>
        )}
      </View>
      <Pressable
        style={tailwind(
          "ml-5 px-6 py-2 bg-colorSkyblue_100 w-[98px] rounded-lg flex justify-center items-center"
        )}
        onPress={() => submit()}
      >
        <Text style={tailwind("text-white font-poppins-regular")}>Submit</Text>
      </Pressable>
    </View>
  );
};

export default CancelPopup;
