import { Image } from 'expo-image';
import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Provider as PaperProvider, Button, Text } from 'react-native-paper';
import { DatePickerModal, registerTranslation, en  } from 'react-native-paper-dates';
import { useDispatch, useSelector } from 'react-redux';
import { useTailwind } from 'tailwind-rn';
import { setEndDate, setStartDate } from '../utils/redux/slice/DateFilterSlice';

registerTranslation('en', en);

const DatePicker = () => {
  const st = useSelector((state) => state?.dateFilter.startDate);
  const end = useSelector((state) => state?.dateFilter.endDate);
  const [range, setRange] = useState({ startDate: parseLocaleDateString(st), endDate: parseLocaleDateString(end)});
  const [open, setOpen] = useState(false);
  const tailwind = useTailwind();
  const dispatch = useDispatch();

  const onDismiss = () => {
    setOpen(false);
  };

  const onConfirm = ({ startDate, endDate }) => {
    setOpen(false);
    setRange({ startDate, endDate });
    startDate = startDate.toLocaleDateString('en-US');
    endDate = endDate.toLocaleDateString('en-US');
    dispatch(setStartDate(startDate));
    dispatch(setEndDate(endDate));
  };

  function parseLocaleDateString(localeDateString) {
    const [month, day, year] = localeDateString.split('/').map(Number);
    return new Date(year, month - 1, day); // month is 0-based in JavaScript Date
  }

  return (
    // <PaperProvider>
      <View style={tailwind('')}>
        <View style={tailwind("flex flex-row justify-between border border-black rounded-lg relative")}>
          <Button onPress={() => setOpen(true)} mode="contained" style={tailwind("min-w-[240px] bg-white ")}>
            <Text style={tailwind("text-black font-poppins-regular mr-auto")}>
            {/* {range.startDate ? range.startDate : 'Not selected'} -  {range.endDate ? range.endDate : 'Not selected'} */}
            {range.startDate ? range.startDate.toLocaleDateString('en-US') : 'Not selected'} -  {range.endDate ? range.endDate.toLocaleDateString('en-US') : 'Not selected'}
            </Text>
          
          
          </Button>
          <Image
                style={[tailwind(" w-[30px] h-[30px] absolute right-[8px] top-[5px]")]}
                contentFit="contain"
                source={require("../assets/calendar.svg")}
              />
        </View>
 
        {/* <Text style={styles.label}>
          Start Date: {range.startDate ? range.startDate.toDateString() : 'Not selected'}
        </Text>
        <Text style={styles.label}>
          End Date: {range.endDate ? range.endDate.toDateString() : 'Not selected'}
        </Text> */}
        <DatePickerModal
          mode="range"
          visible={open}
          onDismiss={onDismiss}
          startDate={range.startDate}
          endDate={range.endDate}
          label= "Select Range"
          onConfirm={onConfirm}
          presentationStyle='formSheet'
          calendarIcon= {require('../assets/calendar.svg')}
          closeIcon= {require('../assets/-icon-close.png')}
          editIcon= {require('../assets/edit-icon.svg')}
        />
      </View>
    // </PaperProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  label: {
    marginTop: 20,
    fontSize: 16,
  },
});

export default DatePicker;
