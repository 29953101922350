import { configureStore } from '@reduxjs/toolkit';
import appHeaderReducer from './slice/AppHeaderSlice';
import userReducer from './slice/UserSlice';
import storeReducer from './slice/StoreSlice';
import ordersReducer from './slice/OrdersSlice';
import dateFilterReducer from './slice/DateFilterSlice';

export const store = configureStore({
  reducer: {
    appHeader: appHeaderReducer,
    user: userReducer,
    store: storeReducer,
    orders: ordersReducer,
    dateFilter: dateFilterReducer,
}
});