import AsyncStorage from "@react-native-async-storage/async-storage";
import React from "react";
import { Pressable, Text, View, TextInput } from "react-native";
import { Icon, RadioButton } from "react-native-paper";
import { useSelector } from "react-redux";
import { useTailwind } from "tailwind-rn";
import { setstorestatus } from "../api";
import { Image } from "expo-image";

const StoreClosePopup = ({ hideModal, dispatchIsStoreActive, selectedStoreTemp }) => {
  const [checked, setChecked] = React.useState(
    `Store will re-open in 5 minutes.`
  );
  const [otherReason, setOtherReason] = React.useState("");
  const tailwind = useTailwind();

  const selectedStore = selectedStoreTemp || useSelector((state) => state?.store?.selectedStore);

  const submit = () => {
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        const today = new Date();
        setstorestatus(
          selectedStore?.status === 1 ? 0 : 1,
          today.toISOString(),
          checked !== "custom" ? checked : otherReason,
          token,
          selectedStore?.storeId
        ).then((res) => {
          dispatchIsStoreActive(res.data.message, true);
        });
      }
    });
    hideModal();
  };

  return (
    <View style={tailwind("font-poppins-regular mb-[20px]")}>
      <View
        style={tailwind(
          "flex flex-row justify-between bg-colorSkyblue_100 p-5 items-center rounded-t-lg"
        )}
      >
        <Text
          style={tailwind("text-2xl text-white font-bold font-poppins-bold")}
        >
          Store Closed Message
        </Text>
        <Pressable onPress={() => hideModal()}>
          {/* <Icon source="close" size={28} color="white" /> */}
          <Image
                style={tailwind("w-[24px] h-[24px]")}
                contentFit="cover"
                source={require("../assets/-icon-close.png")}
              />
        </Pressable>
      </View>
      <View style={tailwind("p-4")}>
        {[5, 15, 30, 60].map((item) => {
          return (
            <Pressable
              style={tailwind(
                "flex flex-row items-center mb-2 w-[calc(100%-32px)]"
              )}
              onPress={() =>
                setChecked(`Store will re-open in ${item} minutes.`)
              }
            >
              <RadioButton.Android
                value={`Store will re-open in ${item} minutes.`}
                status={
                  checked === `Store will re-open in ${item} minutes.`
                    ? "checked"
                    : "unchecked"
                }
                onPress={() =>
                  setChecked(`Store will re-open in ${item} minutes.`)
                }
                color="blue"
              />
              <Text
                style={tailwind("font-poppins-regular")}
              >{`Store will re-open in ${item} minutes.`}</Text>
            </Pressable>
          );
        })}

        <Pressable
          style={tailwind(
            "flex flex-row items-center mb-2 font-poppins-regular"
          )}
          onPress={() => setChecked("custom")}
        >
          <RadioButton.Android
            value="custom"
            status={checked === "custom" ? "checked" : "unchecked"}
            onPress={() => setChecked("custom")}
            color="blue"
          />
          <Text style={tailwind("font-poppins-regular")}>Custom</Text>
        </Pressable>

        {checked === "custom" && (
          <View style={tailwind("ml-4  w-[calc(100%-16px)]")}>
            <TextInput
              style={tailwind(
                "w-[calc(100%-16px)] h-10 border border-gray-300 rounded-lg px-2 ml-4"
              )}
              onChange={(e) => setOtherReason(e.target.value)}
              value={otherReason}
            />
          </View>
        )}
      </View>
      <Pressable
        style={tailwind(
          "ml-5 px-6 py-2 bg-colorSkyblue_100 w-[98px] rounded-lg flex justify-center items-center"
        )}
        onPress={() => submit()}
      >
        <Text style={tailwind("text-white font-poppins-regular")}>Submit</Text>
      </Pressable>
    </View>
  );
};

export default StoreClosePopup;
