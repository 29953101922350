import {
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
StatusBar,
Dimensions
} from "react-native";
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { useTailwind } from "tailwind-rn";
import { useEffect, useRef, useState } from "react";
import Container from "./Container";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useLinkTo, useRoute } from "@react-navigation/native";
import BottomNavigation from "./BottomNavigation";
import Sidebar from "./Sidebar";
import SidebarMobile from "./SidebarMobile";
import AppHeader from "./AppHeader";
import { ActivityIndicator, Modal, Portal } from "react-native-paper";
import { getorderlist, getstorelist } from "../api";

import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedStore,
  setStoreListData,
} from "../utils/redux/slice/StoreSlice";
import { io } from "socket.io-client";
import {
  setNewOrdersCount,
} from "../utils/redux/slice/OrdersSlice";
import ConfirmationPopup from "./ConfirmationPopup";
import { Audio } from "expo-av";

// const SOCKET_URL = "http://localhost:4000/";
const socket = io(process.env.SOCKET_URL);

const Layout = ({ children, setShowLoader, showLoader }) => {
  const [showSidebarMenu, setShowSidebarMenu] = useState(false);
  const [storedSound, setStoredSound] = useState();
  const [modalChild, setModalChild] = useState(<></>);
  const [visible, setVisible] = useState(false);
  const hiddenRef = useRef(null);
  const route = useRoute();

  async function playSound() {
    const { sound } = await Audio.Sound.createAsync(
      require("../sounds/alert.wav")
    );
    setStoredSound(sound);
    // console.log("Playing Sound");
    await sound.playAsync();
  }

  const stopSound = () => {
    storedSound ? storedSound.unloadAsync() : undefined;
    // console.log("sound stopped");
  };

  const showModal = () => setVisible(true);
  const hideModal = () => {
    setVisible(false);
    stopSound();
  };

  useEffect(() => {
    if(!visible){
      stopSound();
    }
  }, [visible])

  const linkTo = useLinkTo();
  const tailwind = useTailwind();
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.store?.selectedStore.storeId);

  AsyncStorage.getItem("token").then((token) => {
    if (token === null || token === "null" || token === undefined || !token) {
      setTimeout(() => linkTo("/login"), 100);
    }
  });

  useEffect(() => {
    AsyncStorage.getItem("userId").then((userId) => {
      socket.auth = { userId: userId };
      socket.connect(process.env.SOCKET_URL);
    });
  }, [socket]);

  useEffect(() => {
    if(store){
      socket.emit("joinRoom", { roomId: store });
    }
    return () => socket.off("joinRoom"); // add this line to your code
  }, [dispatch, store]);

  useEffect(() => {
    socket.on("orderReceived", (data) => {
      // console.log(data, 'data socket')
      fetchOrders(1);
    });

    return () => socket.off("orderReceived"); // add this line to your code
  }, [socket]);

  useEffect(() => {
    return storedSound
      ? () => {
          console.log("Unloading Sound");
        }
      : undefined;
  }, [storedSound]);

  useEffect(() => {
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        getstorelist(token)
          .then((res) => {
            if (res.data.status === 200 || res.data.status === "200") {
              dispatch(setStoreListData(res.data.message));
              dispatch(setSelectedStore(res?.data?.message[0]));
            } else {
              dispatch(setStoreListData([]));
            }
          })
          .catch((err) => {
            console.log("Error in Store list", err);
          });
      }
    });
  }, [dispatch]);

  const fetchOrders = (checkNewOrders) => {
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        getorderlist(10, 1, token, dispatch)?.then((res) => {
          checkForNewOrders(res.data.order, checkNewOrders);
          // checkForNewOrders(res.data.order, true);
        });
      }
    });
  };

  useEffect(() => {
    fetchOrders(0);
  }, []);

  const checkForNewOrders = (ordersData, checkNewOrders) => {
    const latestNewOrders = ordersData.filter(
      (order) => order?.statusCode <= 2
    );

    if (latestNewOrders.length > 0 && checkNewOrders) {
      setModalChild(() => {
        return (
          <ConfirmationPopup
            title={`${latestNewOrders.length} New Orders`}
            question="Go to Orders Page"
            onSubmit={() => {
              linkTo("/orderDashboard");
              stopSound();
            }}
            hideModal={() => {
              hideModal();
              stopSound();
            }}
          />
        );
      });
      playSound();
      showModal();
    }

    dispatch(setNewOrdersCount(latestNewOrders.length));
  };

  const containerStyle = {
    backgroundColor: "white",
    zIndex: 100,
    borderRadius: 10,
  };

  const modalStyles = {
    zIndex: 100,
    width: Platform.OS === "web" ? "500px" : "10px",
    marginHorizontal: "auto",
  };

  if (Platform.OS !== "web") {
    modalStyles.marginHorizontal = 10;
  }

  // useEffect(() => {
  //   if(hiddenRef.current){
  //     console.log(hiddenRef, 'hidden')
  //     hiddenRef?.current?.click()
  //   }
  // }, [hiddenRef])

  return (
      <SafeAreaView
        style={tailwind(
          "relative bg-gray-200 overflow-hidden h-full w-full flex-1 flex"
        )}
      >
        <Portal>
          <Modal
            visible={visible}
            onDismiss={() => {
              hideModal();
              stopSound();
            }}
            contentContainerStyle={containerStyle}
            style={modalStyles}
          >
            {modalChild}
          </Modal>
        </Portal>
        {/* this is the hidden button to enable audio play because on first time app opening audio is not playing because the user is not interacted with the ui */}
        {/* <Pressable style={tailwind('')} ref={hiddenRef} onPress={() => console.log('hidden pressed')}></Pressable> */}
        {showLoader && (
          <ActivityIndicator
            size="large"
            animating={showLoader}
            style={tailwind("absolute left-[50%] top-[50%] z-[150]")}
            color="#00b6d3"
          />
        )}
        {showLoader && (
          <View
            style={tailwind(
              "absolute h-screen w-screen opacity-70 bg-white z-[100]"
            )}
          ></View>
        )}
        {/* Menu Sidebar for Mobile */}
        <View
          style={tailwind(
            `z-[10] absolute flex flex-row left-0 top-0 w-full h-full ${
              showSidebarMenu ? "" : "hidden"
            }  sm:hidden`
          )}
        >
          <SidebarMobile />
          <Pressable
            style={tailwind(
              `w-[20%] xsm:w-[40%] h-full bg-white opacity-70 bg-gray-500`
            )}
            onPress={() => setShowSidebarMenu(false)}
          ></Pressable>
        </View>

        <View style={tailwind(`py-2.5 px-2 sm:px-4 w-full ${(Dimensions.get('window').width<870 && Platform.OS==="web")?"h-[89%]":"h-[91%]"} md:h-full`)}>
          {/* <View style={tailwind('py-2.5 px-2 sm:px-4 w-full h-[calc(100%-75px)] md:h-full')}> */}
          {/* Header */}
          <AppHeader setShowSidebarMenu={setShowSidebarMenu} />

          {/* Main Content */}
          {/* <ScrollView
            style={tailwind("h-full")}
            contentContainerStyle={tailwind("h-full")}
            scrollEnabled={route.name==="Items"}
          > */}
          <View style={[tailwind("flex-row h-[97%] md:h-[calc(100%-90px)]")]}>
            {/* Sidebar */}
            <View style={[tailwind("hidden md:flex"), styles.shadow]}>
              <Sidebar />
            </View>

            <Container children={children}></Container>
          </View>
          {/* </ScrollView> */}
        </View>
        <BottomNavigation />
      </SafeAreaView>
  );
};

const styles = StyleSheet.create({
    shadow: {
      shadowColor: "#000", // Shadow color
      shadowOffset: { width: 0, height: 0 }, // Offset for shadow
      shadowOpacity: 0.12, // Opacity of the shadow
      shadowRadius: 13, // Radius of the shadow blur
    },
  transitShadowBox: {
    shadowOpacity: 1,
    shadowOffset: {
      width: 0,
      height: 39,
    },
    shadowColor: "rgba(0, 0, 0, 0)",
  },

  sidebar: {
    shadowRadius: 1,
    // elevation: 1,
    shadowOpacity: 1,
    shadowOffset: {
      width: 0,
      height: 39,
    },
    shadowColor: "rgba(0, 0, 0, 0)",
  },

  transit: {
    shadowRadius: 14,
    // elevation: 14,
  },
});

export default Layout;
