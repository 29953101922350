import { View } from "react-native";
import { Image } from "expo-image";
import { useTailwind } from "tailwind-rn";

const Container = ({children}) => {

    const tailwind = useTailwind();

    return (
        <View
        style={[
          tailwind(
            "w-full flex-[2_0_70%] md:flex-[2_0_88%] flex flex-col md:flex-row overflow-y-scroll md:overflow-y-hidden"
          ),
        ]}>
            {children}
        </View>
    )
}

export default Container;