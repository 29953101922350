import { Image } from "expo-image";
import { Pressable, Text, View } from "react-native";
import { Icon } from "react-native-paper";
import { useSelector } from "react-redux";
import { useTailwind } from "tailwind-rn";

const ConfirmationPopup = ({
  title,
  onSubmit,
  hideModal,
  question = "Are you sure?",
}) => {
  const tailwind = useTailwind();

  return (
    <View>
      <View
        style={tailwind(
          "flex flex-row justify-between bg-colorSkyblue_100 p-5 items-center rounded-t-lg"
        )}
      >
        <Text
          style={tailwind("text-2xl text-white font-bold font-poppins-bold")}
        >
          {title}
        </Text>
        <Pressable onPress={() => hideModal()}>
          {/* <Icon source="close" size={28} color="white" /> */}
          <Image
                style={tailwind("w-[24px] h-[24px]")}
                contentFit="cover"
                source={require("../assets/-icon-close.png")}
              />
        </Pressable>
      </View>
      <View style={tailwind("pb-5")}>
        <Text
          style={tailwind(
            "text-center p-5 font-poppins-regular text-xl font-poppins-semiBold"
          )}
        >
          {question}
        </Text>
        <View style={tailwind("flex flex-row justify-evenly w-full")}>
         
          <Pressable
            style={tailwind("bg-colorTomato_100 py-2 px-9 rounded-lg")}
            onPress={hideModal}
          >
            <Text style={tailwind("font-poppins-bold text-white")}>No</Text>
          </Pressable>
          <Pressable
            style={tailwind("bg-colorLimegreen_100 py-2 px-8 rounded-lg")}
            onPress={() => {
              onSubmit();
              hideModal();
            }}
          >
            <Text style={tailwind("font-poppins-bold text-white")}>Yes</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default ConfirmationPopup;
