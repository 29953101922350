import * as React from "react";
import {
  View,
  StyleSheet,
  Text,
  SafeAreaView,
  Dimensions,
  Platform,
} from "react-native";
import { Menu, Button, RadioButton } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { useTailwind } from "tailwind-rn";
import { setSelectedStore } from "../utils/redux/slice/StoreSlice";
import { Image } from "expo-image";
import DatePicker from "./DatePicker";

const ReportsHeader = ({setcurrentStore}) => {
  const [visible, setVisible] = React.useState(false);
  const selectedStore = useSelector((state) => state.store.selectedStore);
  const allStores = useSelector((state) => state.store.storeListData);
  const [checked, setChecked] = React.useState(selectedStore.storeId);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);
  const tailwind = useTailwind();
  const dispatch = useDispatch();
  const menuIcon = require("../assets/menu-down.svg");

  React.useEffect(() => {
    if ((!checked || checked === "") && selectedStore) {
      setChecked(selectedStore.storeId);
      // setcurrentStore(selectedStore.storeId);
    }
  }, [selectedStore, dispatch]);

  const menuStyles = {
    zIndex: 100,
    width: 150,
    color: "black",
    top: 151,
    left: Dimensions.get("window").width >= 870 ? 130 : "3%",
  };
  return (
    <View
      style={tailwind(
        "flex flex-row justify-between bg-white w-full rounded-[7px]  max-h-full p-2 items-center"
      )}
    >
      <View
        style={tailwind(
          "bg-white rounded-[7px] h-full justify-center items-center relative"
        )}
      >
        <Menu
          visible={visible}
          onDismiss={closeMenu}
          contentStyle={{ backgroundColor: "white" }}
          style={menuStyles}
          anchor={
            <View style={tailwind("")}>
              <Button
                style={tailwind(
                  "font-poppins-regular h-full flex items-center"
                )}
                onPress={openMenu}
              >
                <Text
                  style={tailwind(
                    "font-poppins-bold text-lg text-colorSkyblue_100 flex flex-row"
                  )}
                >
                  {`${selectedStore.storeId}`}
                  <View style={tailwind("flex flex-row items-center")}>
                    <Image
                      style={tailwind(
                        `w-[15px] h-[9px] mb-[${
                          Platform.OS === "web" ? 3 : 6
                        }px]`
                      )}
                      contentFit="cover"
                      source={menuIcon}
                    />
                  </View>
                </Text>
              </Button>
            </View>
          }
        >
          {allStores &&
            allStores?.map((item, index) => {
              return (
                <View
                  key={`stores-${index}`}
                  style={tailwind(
                    "flex flex-row items-center text-white mb-2 w-[calc(100%-32px)]"
                  )}
                >
                  <RadioButton.Android
                    value={item.storeId}
                    status={checked === item.storeId ? "checked" : "unchecked"}
                    onPress={() => {
                      setChecked(item.storeId);
                      dispatch(setSelectedStore(item));
                    }}
                    color="blue"
                  />
                  <Text style={tailwind("font-poppins-regular text-black")}>
                    {item.storeId}
                  </Text>
                </View>
              );
            })}
        </Menu>
      </View>
      <SafeAreaView style={{}}>
        <DatePicker />
      </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default ReportsHeader;
